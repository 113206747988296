import React, { useEffect } from 'react';

import $ from 'jquery';

export function ShippingTable(prop) {
    useEffect(() => {
        $(document).ready(function() {
            $('#kt_datatable').DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: -1,
                responsive: true,
                dom: 'Bfrtip',
                buttons: [ 'excel' ],
                order: [ [ 0, 'asc' ] ],
                language: {
                    paginate: {
                        previous: 'ก่อนหน้า',
                        next: 'ถัดไป'
                    },
                    info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                    infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                    emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                    infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                    zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                },
                oLanguage: {
                    sSearch: 'คำสำคัญ'
                },
                columns: [
                    // {
                    //     title: "ดูรายละเอียด",
                    //     className: "dt-body-center",
                    //     data: null,
                    //     render: function (data, type, row) {
                    //         // if (data.position === "") {
                    //         return `<button
                    //                     type="button"
                    //                     class="btn btn-icon btn-primary view-info"
                    //                     >
                    //                         <i class="fa fa-search"></i>
                    //                 </button>`;
                    //         // } else {
                    //         //     return "";
                    //         // }
                    //     },
                    // },
                    {
                        title: 'หมายเลขคำสั่งซื้อ',
                        data: 'order_number'
                    },
                    {
                        title: 'วันที่ชำระเงิน',
                        data: 'paid_date'
                    },
                    {
                        title: 'ชื่อสมาชิก',
                        // data: "name",
                        data: function(value) {
                            if (value.name !== null && value.name !== '') {
                                return value.name;
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: 'หมายเลขที่สั่งซื้อ',
                        data: function(value) {
                            if (value.number !== null && value.number !== '') {
                                return value.number;
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: 'ราคา',
                        data: 'amount'
                    },
                    {
                        title: 'หมายเลขติดต่อกลับ',
                        data: function(value) {
                            if (value.shipping.contact_number !== null && value.shipping.contact_number !== '') {
                                return value.shipping.contact_number;
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: 'ที่อยู่จัดส่ง',
                        data: function(value) {
                            let arrayaddress = [
                                value.shipping.address,
                                value.shipping.district,
                                value.shipping.amphur,
                                value.shipping.province,
                                value.shipping.zipcode
                            ];
                            return arrayaddress.join(' ');
                        }
                    },
                    {
                        title: 'หมายเลขพัสดุ',
                        data: function(value) {
                            if (value.tracking_number !== '') {
                                return value.tracking_number;
                            } else {
                                return 'ไม่มีข้อมูล';
                            }
                        }
                    },
                    {
                        title: 'วันที่จัดส่ง',
                        data: function(value) {
                            if (value.shipped_date !== 'None') {
                                return value.shipped_date;
                            } else {
                                return 'ไม่มีข้อมูล';
                            }
                        }
                    }
                    // {
                    //     title: "ชื่อสมาชิก",
                    //     // data: "name",
                    //     data: function (value) {
                    //         if (value.name !== null && value.name !== "") {
                    //             return value.name;
                    //         } else {
                    //             return "-";
                    //         }
                    //     },
                    // },
                    // {
                    //     title: "หมายเลข",
                    //     data: "phonenumber",
                    //     // data: function (value) {
                    //     //     if (
                    //     //         value.phonenumber !== null &&
                    //     //         value.phonenumber !== ""
                    //     //     ) {
                    //     //         return (
                    //     //             value.phonenumber.slice(0, 3) +
                    //     //             "-" +
                    //     //             value.phonenumber.slice(3, 6) +
                    //     //             "-" +
                    //     //             value.phonenumber.slice(6)
                    //     //         );
                    //     //     } else {
                    //     //         return "-";
                    //     //     }
                    //     // },
                    // },
                    // {
                    //     title: "ระดับผลงานปัจจุบัน",
                    //     className: "dt-body-center",
                    //     data: function (value) {
                    //         if (value.position === "SS") {
                    //             return '<span class="label label-warning label-pill label-inline mx-auto my-auto">Super Star Dealer</span>';
                    //         } else if (value.position === "S") {
                    //             return '<span class="label label-success label-pill label-inline mx-auto my-auto">Star Dealer</span>';
                    //         } else if (value.position === "B") {
                    //             return '<span class="label label-dark label-pill label-inline mx-auto my-auto">Basic Dealer</span>';
                    //         } else {
                    //             return '<span class="label label-primary label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                    //         }
                    //     },
                    // },
                ]
            });
        });
    }, []);

    return <table id='kt_datatable' className='table table-striped table-bordered' />;
}
