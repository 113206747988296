import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import Moment from "moment";

import DatePicker, { utils } from "react-modern-calendar-datepicker";

import Select, { components } from "react-select";

import TextareaAutosize from "react-textarea-autosize";

import Swal from "sweetalert2/dist/sweetalert2.js";

import { DealerTable } from "./Table";
import { DealerInfoTable } from "./TabTable";

import config from "../../../config";
import initaddress from "../../../address";

const myCustomLocale = {
    // months list by order
    months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ],
    // week days by order
    weekDays: [
        {
            name: "อาทิตย์", // used for accessibility
            short: "อา", // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: "จันทร์",
            short: "จ",
        },
        {
            name: "อังคาร",
            short: "อ",
        },
        {
            name: "พุธ",
            short: "พ",
        },
        {
            name: "พฤหัสบดี",
            short: "พฤ",
        },
        {
            name: "ศุกร์",
            short: "ศ",
        },
        {
            name: "เสาร์",
            short: "ส",
            isWeekend: true,
        },
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: "Next Month", // texts in the date picker
    previousMonth: "Previous Month", // texts in the date picker
    openMonthSelector: "Open Month Selector", // texts in the date picker
    openYearSelector: "Open Year Selector", // texts in the date picker
    closeMonthSelector: "Close Month Selector", // texts in the date picker
    closeYearSelector: "Close Year Selector", // texts in the date picker
    defaultPlaceholder: "Select...", // texts in the date picker
    from: "from", // for input range value
    to: "to", // for input range value
    digitSeparator: ",", // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false, // is your language rtl or ltr?
};

const minimumDate = {
    year: 2020,
    month: 12,
    day: 31,
};

const controlStyles = {
    borderRadius: "0.85rem",
    // padding: "5px",
    background: "#fff",
    // color: "white",
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#fff",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 1051,
        // position: "relative",
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
    }),
};

const banklist = [
    // {
    //   "value": "001",
    //   "label": "ธนาคารแห่งประเทศไทย"
    // },
    {
        value: "002",
        label: "ธนาคารกรุงเทพ จำกัด (มหาชน)",
    },
    {
        value: "014",
        label: "ธนาคารไทยพาณิชย์จำกัด (มหาชน)",
    },
    {
        value: "004",
        label: "ธนาคารกสิกรไทย จำกัด (มหาชน)",
    },
    // {
    //   "value": "005",
    //   "label": "เดอะรอยัลแบงก์อ๊อฟสกอตแลนด์เอน็ วี"
    // },
    {
        value: "006",
        label: "ธนาคารกรุงไทย จำกัด (มหาชน)",
    },
    // {
    //   "value": "008",
    //   "label": "ธนาคารเจพีมอร์แกน เชส"
    // },
    {
        value: "011",
        label: "ธนาคารทหารไทย จำกัด (มหาชน)",
    },
    {
        value: "017",
        label: "ธนาคารซิตี้แบงค์",
    },
    // {
    //   "value": "018",
    //   "label": "ชูมิโตโม มิตซุย แบงก์ คอร์ปอเรชั่น"
    // },
    {
        value: "020",
        label: "ธนาคารสแตนดาร์ด ชาร์เตอร์ด (ไทย) จำกัด",
    },
    {
        value: "022",
        label: "ธนาคาร ซีไอเอม็ บีไทย จำกัด (มหาชน)",
    },
    {
        value: "024",
        label: "ธนาคารยูโอบีจำกัด (มหาชน)",
    },
    {
        value: "025",
        label: "ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)",
    },
    // {
    //   "value": "026",
    //   "label": "ธนาคาร เมกะ สากลพาณิชย์จำกัด (มหาชน)"
    // },
    // {
    //   "value": "027",
    //   "label": "ธนาคารแห่ง อเมริกา เนชั่นแนล แอสโซซิเอชั่น"
    // },
    {
        value: "030",
        label: "ธนาคารออมสิน",
    },
    // {
    //   "value": "031",
    //   "label": "ธนาคารฮ่องกงและเซี่ยงไฮ้แบงก์คอร์ปอเรชั่น จำกัด"
    // },
    // {
    //   "value": "032",
    //   "label": "ธนาคารดอยซ์แบงก์"
    // },
    {
        value: "033",
        label: "อาคารสงเคราะห์",
    },
    {
        value: "034",
        label: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    },
    // {
    //   "value": "039",
    //   "label": "ธนาคารมิซูโฮ คอร์เปอเรท"
    // },
    // {
    //   "value": "045",
    //   "label": "ธนาคาร บีเอ็น พีพารีบาส์"
    // },
    {
        value: "052",
        label: "ธนาคารแห่ง ประเทศจีน (ไทย) จำกัด (มหาชน)",
    },
    {
        value: "065",
        label: "ธนาคารธนชาต จำกัด (มหาชน)",
    },
    {
        value: "066",
        label: "ธนาคารอิสลามแห่งประเทศไทย",
    },
    {
        value: "067",
        label: "ธนาคารทิสโก้จำกัด (มหาชน)",
    },
    {
        value: "069",
        label: "ธนาคารเกียรตินาคิน จำกัด (มหาชน)",
    },
    {
        value: "070",
        label: "ธนาคารไอซีบีซี(ไทย) จำกัด (มหาชน)",
    },
    {
        value: "071",
        label: "ธนาคารไทยเครดิตอเพื่อยย่อยจำกัด (มหาชน)",
    },
    {
        value: "073",
        label: "ธนาคารแลนด์แอนด์เฮาส์",
    },
    // {
    //   "value": "079",
    //   "label": "ธนาคารเอเอ็น แซด (ไทย) จำกัด (มหาชน)"
    // }
];

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton:
            "btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
        cancelButton:
            "btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
    },
    buttonsStyling: false,
});

export function DealerReport() {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({});
    const [dealerid, setDealerid] = useState("");
    const [showModal, setShow] = useState(false);
    const [modalres, setModalResponse] = useState({});
    const [activeTab, setActiveTab] = useState("1");

    const [name, setName] = useState("");
    const [date, setDate] = useState({
        from: null,
        to: null,
    });
    const [citizenid, setCitizenid] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [ekyc, setEkyc] = useState("");

    const [editname, setEditName] = useState("");
    const [editcitizenid, setEditCitizenid] = useState("");
    const [editcontactnumber, setEditContactNumber] = useState("");
    const [editemail, setEditEmail] = useState("");
    const [editgeo, setEditGeo] = useState("");
    const [editprovince, setEditProvince] = useState("");
    const [editdistrict, setEditDistrict] = useState("");
    const [editsubdistrict, setEditSubDistrict] = useState("");
    const [editzipcode, setEditZipcode] = useState("");
    const [editaddress, setEditAddress] = useState("");
    const [editbank, setEditBank] = useState("");
    const [editaccountname, setEditAccountname] = useState("");
    const [editaccountnumber, setEditAccountnumber] = useState("");

    // init value
    const [mainprovince, setMainProvince] = useState([]);
    const [maindistrict, setMainDistrict] = useState([]);
    const [mainsubdistrict, setMainSubDistrict] = useState([]);

    const handleClose = () => {
        setActiveTab("1");
        setDealerid("");
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to
                    ? date.from.year +
                      "/" +
                      ("0" + date.from.month).slice(-2) +
                      "/" +
                      ("0" + date.from.day).slice(-2) +
                      " - " +
                      date.to.year +
                      "/" +
                      ("0" + date.to.month).slice(-2) +
                      "/" +
                      ("0" + date.to.day).slice(-2)
                    : ""
            }
            className="form-control rounded-lg"
            type="text"
            id="dateperiod"
            name="dateperiod"
        />
    );
    useEffect(() => {
        let postparam = {
            filter: { start: Moment().format("YYYY-MM-DD") },
        };
        getDealer(postparam);
    }, []);

    const syncDealer = (postparam) => {
        setLoading(true);
        fetch(config.url + "admin/member/sync-dealer-name", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: "ดำเนินการ Sync ข้อมูลเรียบร้อย",
                        icon: "success",
                        reverseButtons: true,
                        confirmButtonText: "ยืนยัน",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    })
                } else {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: "ไม่สามารถดำเนินการ Sync ข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
                        icon: "warning",
                        reverseButtons: true,
                        confirmButtonText: "ยืนยัน",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    })
                }
            })
            .catch((err) => {
                setLoading(false);
                swalWithBootstrapButtons.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง",
                    icon: "error",
                    reverseButtons: true,
                    confirmButtonText: "ยืนยัน",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                })
            });
    }

    const isNumeric = (num) => {
        return !isNaN(num);
    };

    const handleSearch = () => {
        let param = {};
        let postparam = {};
        if (name !== "") {
            param["name"] = name;
        }
        if (citizenid !== "") {
            param["id_card"] = citizenid;
        }
        if (phonenumber !== "") {
            param["number"] = phonenumber;
        }
        if (date.from !== null && date.to !== null) {
            param["start"] =
                date.from.year +
                "-" +
                ("0" + date.from.month).slice(-2) +
                "-" +
                ("0" + date.from.day).slice(-2);
            param["end"] =
                date.to.year +
                "-" +
                ("0" + date.to.month).slice(-2) +
                "-" +
                ("0" + date.to.day).slice(-2);
        }
        if (param) {
            postparam["filter"] = param;
        }
        setLoading(true);
        getDealer(postparam);
    };

    const getDealer = (postparam) => {
        setResponse({});
        fetch(config.url + "admin/member/lists", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const getDealerInfo = (citizenid) => {
        setLoading(true);
        setModalResponse({});
        fetch(config.url + "admin/member/detail", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({ id: citizenid }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.resposecode === "0") {
                    setModalResponse(result);
                    handleShow();
                    setEditName(result.detail.personal_info.name);
                    setEditCitizenid(result.detail.personal_info.personal_id);
                    setEditContactNumber(
                        result.detail.personal_info.contact_number
                    );
                    setEditEmail(result.detail.personal_info.email);
                    for (const prop in initaddress.geo) {
                        if (
                            initaddress.geo[prop]["value"] ===
                            result.detail.address_info.geo_id.toString()
                        ) {
                            setEditGeo(initaddress.geo[prop]);
                            let thisProvince = [];
                            let geoindex = 0;
                            for (const prop in initaddress.province) {
                                if (
                                    initaddress.province[prop]["geoid"] ===
                                    result.detail.address_info.geo_id.toString()
                                ) {
                                    thisProvince[geoindex] =
                                        initaddress.province[prop];
                                    geoindex += 1;
                                }
                            }
                            setMainProvince(thisProvince);
                            break;
                        }
                    }
                    for (const prop in initaddress.province) {
                        if (
                            initaddress.province[prop]["value"] ===
                            result.detail.address_info.province_id.toString()
                        ) {
                            setEditProvince(initaddress.province[prop]);
                            let thisDistrict = [];
                            let provinceindex = 0;
                            for (const prop in initaddress.district) {
                                if (
                                    initaddress.district[prop]["provinceid"] ===
                                    result.detail.address_info.province_id.toString()
                                ) {
                                    thisDistrict[provinceindex] =
                                        initaddress.district[prop];
                                    provinceindex += 1;
                                }
                            }
                            setMainDistrict(thisDistrict);
                            break;
                        }
                    }
                    for (const prop in initaddress.district) {
                        if (
                            initaddress.district[prop]["value"] ===
                            result.detail.address_info.district_id.toString()
                        ) {
                            setEditDistrict(initaddress.district[prop]);
                            let thisSubDistrict = [];
                            let districtindex = 0;
                            for (const prop in initaddress.subdistrict) {
                                if (
                                    initaddress.subdistrict[prop][
                                        "districtid"
                                    ] ===
                                    result.detail.address_info.district_id.toString()
                                ) {
                                    thisSubDistrict[districtindex] =
                                        initaddress.subdistrict[prop];
                                    districtindex += 1;
                                }
                            }
                            setMainSubDistrict(thisSubDistrict);
                            break;
                        }
                    }
                    for (const prop in initaddress.subdistrict) {
                        if (
                            initaddress.subdistrict[prop]["value"] ===
                            result.detail.address_info.subdistrict_id.toString()
                        ) {
                            setEditSubDistrict(initaddress.subdistrict[prop]);
                            break;
                        }
                    }
                    setEditZipcode(result.detail.address_info.zipcode);
                    setEditAddress(result.detail.address_info.address);
                    for (const prop in banklist) {
                        if (
                            parseInt(banklist[prop]["value"]).toString() ===
                            result.detail.bankaccount_info.bank_id.toString()
                        ) {
                            setEditBank(banklist[prop]);
                            break;
                        }
                    }
                    setEditAccountname(
                        result.detail.bankaccount_info.account_name
                    );
                    setEditAccountnumber(
                        result.detail.bankaccount_info.account_no
                    );
                    setEkyc(result.detail.personal_info.active_status);
                } else {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: "ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const selectGeo = (item) => {
        setEditGeo(item);
        setEditProvince("");
        setEditDistrict("");
        setMainDistrict([]);
        setEditSubDistrict("");
        setMainSubDistrict([]);
        setEditZipcode("");
        let thisProvince = [];
        let thisindex = 0;
        if (item) {
            for (const prop in initaddress.province) {
                if (initaddress.province[prop]["geoid"] === item["value"]) {
                    thisProvince[thisindex] = initaddress.province[prop];
                    thisindex += 1;
                }
            }
        }
        setMainProvince(thisProvince);
    };

    const selectProvince = (item) => {
        setEditProvince(item);
        setEditDistrict("");
        setEditSubDistrict("");
        setMainSubDistrict([]);
        setEditZipcode("");
        let thisDistrict = [];
        let thisindex = 0;
        if (item) {
            for (const prop in initaddress.district) {
                if (
                    initaddress.district[prop]["provinceid"] === item["value"]
                ) {
                    thisDistrict[thisindex] = initaddress.district[prop];
                    thisindex += 1;
                }
            }
        }
        setMainDistrict(thisDistrict);
    };

    const selectDistrict = (item) => {
        setEditDistrict(item);
        setEditSubDistrict("");
        setEditZipcode("");
        let thisSubDistrict = [];
        let thisindex = 0;
        if (item) {
            for (const prop in initaddress.subdistrict) {
                if (
                    initaddress.subdistrict[prop]["districtid"] ===
                    item["value"]
                ) {
                    thisSubDistrict[thisindex] = initaddress.subdistrict[prop];
                    thisindex += 1;
                }
            }
        }
        setMainSubDistrict(thisSubDistrict);
    };

    const selectSubDistrict = (item) => {
        setEditSubDistrict(item);
        let thisZipcode = "";
        if (item) {
            thisZipcode = item["zipcode"];
        }
        setEditZipcode(thisZipcode);
    };

    const selectBank = (item) => {
        setEditBank(item);
        // let thisZipcode = "";
        // if (item) {
        //     thisZipcode = item["zipcode"];
        // }
        // setEditZipcode(thisZipcode);
    };

    const updateInfo = () => {
        if (editname === "" || editname === undefined) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณากรอกชื่อดีลเลอร์ให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (editcitizenid === "" || editcitizenid === undefined) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณากรอกหมายเลขบัตรประจำตัวประชาชนให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (editaccountnumber === "" || editaccountnumber === undefined) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณากรอกเบอร์ติดต่อกลับให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        // if (editemail == "" || editemail === undefined) {
        //     swalWithBootstrapButtons.fire({
        //         // title: 'ยกเลิกคำสั่ง',
        //         html: "กรุณากรอกอีเมลให้เรียบร้อบ",
        //         icon: "warning",
        //         // showCancelButton: true,
        //         confirmButtonText: "รับทราบ",
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         allowEnterKey: false,
        //     });
        //     return;
        // }

        if (editgeo === "" || editgeo === undefined || editgeo === null) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกภาคให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (
            editprovince === "" ||
            editprovince === undefined ||
            editprovince === null
        ) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกจังหวัดให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (
            editdistrict === "" ||
            editdistrict === undefined ||
            editdistrict === null
        ) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกเขต/อำเภอให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (
            editsubdistrict === "" ||
            editsubdistrict === undefined ||
            editsubdistrict === null
        ) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกแขวง/ตำบลให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (editzipcode === "" || editzipcode === undefined) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณากรอกรหัสไปรษณีย์ให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (editaddress === "" || editaddress === undefined) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณากรอกที่อยู่ให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (editbank === "" || editbank === undefined || editbank === null) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกธนาคารให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (editaccountname === "" || editaccountname === undefined) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณากรอกชื่อบัญชีธนาคารให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }

        if (editaccountnumber === "" || editaccountnumber === undefined) {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณากรอกเลขบัญชีธนาคารให้เรียบร้อบ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        let postparam = {
            userid: dealerid,
            profile: {
                name: editname,
                contact_mobile: editcontactnumber,
            },
            address: {
                geo_id: editgeo.value,
                province_id: editprovince.value,
                amphur_id: editdistrict.value,
                district_id: editsubdistrict.value,
                address: editaddress,
                zip: editzipcode,
            },
            bank_account: {
                bank_code: editbank.value,
                account_no: editaccountnumber,
                account_name: editaccountname,
            },
        };
        if (editemail !== "") {
            postparam["profile"]["email"] = editemail;
        }
        setLoading(true);
        fetch(config.url + "admin/member/update_profile", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    swalWithBootstrapButtons
                        .fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: "แก้ไขข้อมูลส่วนตัวเรียบร้อยแล้ว",
                            icon: "success",
                            // showCancelButton: true,
                            confirmButtonText: "รับทราบ",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        })
                        .then((result) => {
                            if (result) {
                                handleClose();
                            }
                        });
                } else {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            "ไม่สามารถแก้ไขข้อมูลส่วนตัว กรุณาลองใหม่อีกครั้ง",
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const changeUpline = (postparam) => {
        setLoading(true);
        fetch(config.url + "admin/member/update-upline", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                console.log(result);
                if (result.responsecode === 0) {
                    swalWithBootstrapButtons
                        .fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: "เปลี่ยนผู้แนะนำเรียบร้อยแล้ว",
                            icon: "success",
                            // showCancelButton: true,
                            confirmButtonText: "รับทราบ",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        })
                        .then((result) => {
                            if (result) {
                                handleClose();
                            }
                        });
                } else if (result.responsecode === "101") {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            "ไม่สามารถเปลี่ยนผู้แนะนำได้เนื่องจากผู้แนะนำเดิมไม่ได้ต่อบริษัท",
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                } else if (result.responsecode === "102") {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            "ไม่สามารถเปลี่ยนผู้แนะนำได้เนื่องจากผู้แนะนำใหม่สมัครมาหลังเบอร์ที่ต้องการย้ายผู้แนะนำ",
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                } else if (result.responsecode === "103") {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            "ไม่สามารถเปลี่ยนผู้แนะนำได้เนื่องจากผู้แนะนำใหม่ยังไม่มีตำแหน่งหรือยังไม่ได้ชำระเงิน",
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const activeEkyc = (postparam) => {
        setLoading(true);
        fetch(config.url + "admin/member/active-kyc", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    swalWithBootstrapButtons
                        .fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: "เปลี่ยนสถานะการลงทะเบียนเรียบร้อยแล้ว",
                            icon: "success",
                            // showCancelButton: true,
                            confirmButtonText: "รับทราบ",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        })
                        .then((result) => {
                            if (result) {
                                handleClose();
                            }
                        });
                } else {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: "ไม่สามารถเปลี่ยนสถานะการลงทะเบียน",
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const toggleAccount = () => {
        swalWithBootstrapButtons
            .fire({
                // title: 'ยกเลิกคำสั่ง',
                html: `<div>ต้องการ${
                    ekyc === 0 ? "เปิด" : "ปิด"
                }การใช้งานสมาชิก ใช่หรือไม่?</div>`,
                icon: "question",
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    fetch(config.url + "admin/member/toggle-active", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            secret: config.secret,
                        },
                        body: JSON.stringify({
                            userid: dealerid,
                        }),
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            setLoading(false);
                            console.log(result);
                            if (result.responsecode === 0) {
                                swalWithBootstrapButtons
                                    .fire({
                                        // title: 'ยกเลิกคำสั่ง',
                                        html:
                                            (ekyc === 0 ? "เปิด" : "ปิด") +
                                            "การใช้งานสมาชิกเรียบร้อยแล้ว",
                                        icon: "success",
                                        // showCancelButton: true,
                                        confirmButtonText: "รับทราบ",
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    })
                                    .then((result) => {
                                        if (result) {
                                            handleClose();
                                        }
                                    });
                            } else {
                                swalWithBootstrapButtons.fire({
                                    // title: 'ยกเลิกคำสั่ง',
                                    html:
                                        "ไม่สามารถ" +
                                        (ekyc === 0 ? "เปิด" : "ปิด") +
                                        "การใช้งานสมาชิกได้",
                                    icon: "warning",
                                    // showCancelButton: true,
                                    confirmButtonText: "รับทราบ",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                });
                            }
                        })
                        .catch((err) => {
                            setLoading(false);
                            console.log(err);
                        });
                }
            });
    };

    const activeSim = (postparam) => {
        setLoading(true);
        fetch(config.url + "admin/sim/active-status-tot", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    swalWithBootstrapButtons
                        .fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: "ปรับสถานะซิมเรียบร้อยแล้ว",
                            icon: "success",
                            // showCancelButton: true,
                            confirmButtonText: "รับทราบ",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                        })
                        .then((result) => {
                            if (result) {
                                handleClose();
                            }
                        });
                } else {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: "ไม่สามารถปรับสถานะซิมได้",
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                swalWithBootstrapButtons.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: "ไม่สามารถปรับสถานะซิมได้",
                    icon: "error",
                    // showCancelButton: true,
                    confirmButtonText: "รับทราบ",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            });
    }

    const deleteUser = () => {
        swalWithBootstrapButtons
            .fire({
                // title: 'ยกเลิกคำสั่ง',
                html: `<div>ต้องการยกเลิกสมาชิก ใช่หรือไม่?</div>`,
                icon: "question",
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    fetch(config.url + "admin/member/delete-user", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            secret: config.secret,
                        },
                        body: JSON.stringify({
                            userid: dealerid,
                        }),
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            setLoading(false);
                            console.log(result);
                            if (result.responsecode === 0) {
                                swalWithBootstrapButtons
                                    .fire({
                                        // title: 'ยกเลิกคำสั่ง',
                                        html: "ยกเลิกสมาชิกเรียบร้อยแล้ว",
                                        icon: "success",
                                        // showCancelButton: true,
                                        confirmButtonText: "รับทราบ",
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        allowEnterKey: false,
                                    })
                                    .then((result) => {
                                        if (result) {
                                            handleClose();
                                        }
                                    });
                            } else {
                                swalWithBootstrapButtons.fire({
                                    // title: 'ยกเลิกคำสั่ง',
                                    html: "มีข้อผิดพลาดในการยกเลิกสมาชิก",
                                    icon: "warning",
                                    // showCancelButton: true,
                                    confirmButtonText: "รับทราบ",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                });
                            }
                        })
                        .catch((err) => {
                            setLoading(false);
                            console.log(err);
                        });
                }
            });
    };
    return (
        <>
            {loading ? (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-custom card-stretch gutter-b rounded-lg">
                            <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fa fa-search text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        ค้นหา
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="ordername">
                                            ชื่อดีลเลอร์
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="ordername"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="orderdate">
                                            วันที่สมัครสมาชิก
                                        </label>
                                        <DatePicker
                                            value={date}
                                            onChange={setDate}
                                            renderInput={renderCustomInput}
                                            minimumDate={minimumDate}
                                            maximumDate={utils().getToday()}
                                            locale={myCustomLocale}
                                            wrapperClassName="col-12 p-0"
                                            calendarClassName="responsive-calendar"
                                            calendarPopperPosition="bottom"
                                            shouldHighlightWeekends
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="citizenid">
                                            หมายเลขบัตรประชาชนของดีลเลอร์
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="citizenid"
                                            value={citizenid}
                                            onChange={(e) =>
                                                setCitizenid(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="phonenumber">
                                            เบอร์โทรศัพท์ที่เป็นสมาชิก
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="phonenumber"
                                            value={phonenumber}
                                            onChange={(e) =>
                                                setPhonenumber(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button
                                    type="button"
                                    onClick={handleSearch}
                                    className="btn btn-sm btn-primary rounded-lg"
                                >
                                    <i className="far fa-dot-circle"></i> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="card card-custom card-stretch gutter-b rounded-lg">
                            <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                <div className="card-title">
                                    <h3 className="card-label text-white">
                                        รายงาน
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                {Object.keys(response).length > 0 ? (
                                    <>
                                        {response.resposecode === "0" ? (
                                            <DealerTable
                                                data={response.lists}
                                                getDealerInfo={getDealerInfo}
                                                setDealerid={setDealerid}
                                            />
                                        ) : (
                                            <div className="col-xl-12 text-center m-0 my-25 p-0">
                                                <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                                                <h4 className="mt-3">
                                                    ไม่พบข้อมูลที่ต้องการค้นหา
                                                </h4>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="col-xl-12 text-center m-0 my-25 p-0">
                                        <i className="fas fa-exclamation-circle fa-5x mb-2 text-danger"></i>
                                        <h4 className="mt-3">
                                            ไม่สามารถดึงข้อมูลได้
                                            กรุณาลองใหม่อีกครั้ง
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                show={showModal}
                onHide={handleClose}
                size="xl"
                centered
                aria-labelledby="unactive-number-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="unactive-number-modal"
                        className="col-11 p-0"
                    >
                        ข้อมูลเพิ่มเติม
                    </Modal.Title>
                    <button
                        type="button"
                        className="close col-1 text-right pr-3"
                        onClick={handleClose}
                    >
                        <i className="ki ki-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {modalres.resposecode === "0" ? (
                        <>
                            <div className="row d-flex justify-content-center pb-5">
                                <Nav tabs className="col-12">
                                    <NavItem>
                                        <NavLink
                                            className={`${
                                                activeTab === "1"
                                                    ? "active"
                                                    : null
                                            }`}
                                            onClick={() => setActiveTab("1")}
                                            href="#"
                                        >
                                            ข้อมูลส่วนตัว
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${
                                                activeTab === "2"
                                                    ? "active"
                                                    : null
                                            }`}
                                            onClick={() => setActiveTab("2")}
                                            href="#"
                                        >
                                            ที่อยู่
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${
                                                activeTab === "3"
                                                    ? "active"
                                                    : null
                                            }`}
                                            onClick={() => setActiveTab("3")}
                                            href="#"
                                        >
                                            ข้อมูลบัญชีธนาคาร
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${
                                                activeTab === "4"
                                                    ? "active"
                                                    : null
                                            }`}
                                            onClick={() => setActiveTab("4")}
                                            href="#"
                                        >
                                            เบอร์ปันสุข
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${
                                                activeTab === "5"
                                                    ? "active"
                                                    : null
                                            }`}
                                            onClick={() => setActiveTab("5")}
                                            href="#"
                                        >
                                            แก้ไขข้อมูล
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent
                                    activeTab={activeTab}
                                    className="col-12 mt-5"
                                >
                                    <TabPane tabId="1">
                                        <div className="col-12 mt-5">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="bg-light rounded-lg d-flex flex-column p-3">
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                ชื่อดีลเลอร์
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {
                                                                    modalres
                                                                        .detail
                                                                        .personal_info
                                                                        .name
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                {isNumeric(
                                                                    modalres
                                                                        .detail
                                                                        .personal_info
                                                                        .personal_id
                                                                )
                                                                    ? "หมายเลขบัตรประจำตัวประชาชน"
                                                                    : "หมายเลขพาสปอร์ต"}
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {
                                                                    modalres
                                                                        .detail
                                                                        .personal_info
                                                                        .personal_id
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                เบอร์ติดต่อกลับ
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {modalres.detail.personal_info.contact_number.slice(
                                                                    0,
                                                                    3
                                                                ) +
                                                                    "-" +
                                                                    modalres.detail.personal_info.contact_number.slice(
                                                                        3,
                                                                        6
                                                                    ) +
                                                                    "-" +
                                                                    modalres.detail.personal_info.contact_number.slice(
                                                                        6
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                วันที่ลงทะเบียน
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {
                                                                    modalres
                                                                        .detail
                                                                        .personal_info
                                                                        .register_date
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                อีเมล
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {modalres.detail
                                                                    .personal_info
                                                                    .email !==
                                                                ""
                                                                    ? modalres
                                                                          .detail
                                                                          .personal_info
                                                                          .email
                                                                    : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                สถานะการชำระเงิน
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {modalres.detail
                                                                    .personal_info
                                                                    .status ===
                                                                "active" ? (
                                                                    <span className="label label-success label-pill label-inline mx-auto my-auto">
                                                                        ชำระเรียบร้อยแล้ว
                                                                    </span>
                                                                ) : (
                                                                    <span className="label label-secondary label-pill label-inline mx-auto my-auto">
                                                                        รอการชำระเงิน
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6 my-auto">
                                                                ระงับการใช้งาน
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {/* <Switch
                                                                    on={1}
                                                                    off={0}
                                                                    value={ekyc}
                                                                    onChange={
                                                                        setEkyc
                                                                    }
                                                                    styles={{
                                                                        track: {
                                                                            backgroundColor:
                                                                                "blue",
                                                                        },
                                                                        trackChecked: {
                                                                            backgroundColor:
                                                                                "red",
                                                                        },
                                                                        button: {
                                                                            backgroundColor:
                                                                                "yellow",
                                                                        },
                                                                        buttonChecked: {
                                                                            backgroundColor:
                                                                                "blue",
                                                                        },
                                                                    }}
                                                                /> */}
                                                                <span className="switch switch-danger">
                                                                    <label
                                                                        onClick={
                                                                            toggleAccount
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                ekyc ===
                                                                                0
                                                                            }
                                                                            name="select"
                                                                            readOnly
                                                                        />
                                                                        <span />
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6 my-auto">
                                                                ยกเลิกข้อมูลการสมัคร
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                <button
                                                                    type="button"
                                                                    onClick={
                                                                        deleteUser
                                                                    }
                                                                    className="btn btn-danger btn-sm rounded-lg font-weight-bold text-uppercase"
                                                                >
                                                                    ยกเลิก
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="col-12 mt-5">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="bg-light rounded-lg d-flex flex-column p-3">
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                ที่อยู่
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {
                                                                    modalres
                                                                        .detail
                                                                        .address_info
                                                                        .address
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                {modalres.detail.address_info.province.replace(
                                                                    " ",
                                                                    ""
                                                                ) ===
                                                                "กรุงเทพมหานคร"
                                                                    ? "แขวง"
                                                                    : "ตำบล"}
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {modalres.detail.address_info.subdistrict.replace(
                                                                    " ",
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                {modalres.detail.address_info.province.replace(
                                                                    " ",
                                                                    ""
                                                                ) ===
                                                                "กรุงเทพมหานคร"
                                                                    ? "เขต"
                                                                    : "อำเภอ"}
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {modalres.detail.address_info.district.replace(
                                                                    " ",
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                จังหวัด
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {modalres.detail.address_info.province.replace(
                                                                    " ",
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                รหัสไปรษณีย์
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {
                                                                    modalres
                                                                        .detail
                                                                        .address_info
                                                                        .zipcode
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div className="col-12 mt-5">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="bg-light rounded-lg d-flex flex-column p-3">
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                ธนาคาร
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {
                                                                    modalres
                                                                        .detail
                                                                        .bankaccount_info
                                                                        .bankname
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                ชื่อบัญชีธนาคาร
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {
                                                                    modalres
                                                                        .detail
                                                                        .bankaccount_info
                                                                        .account_name
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="p-2 d-flex">
                                                            <div className="col-6">
                                                                เลขบัญชีธนาคาร
                                                            </div>
                                                            <div className="ml-auto text-right">
                                                                {
                                                                    modalres
                                                                        .detail
                                                                        .bankaccount_info
                                                                        .account_no
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <div className="col-12 mt-5">
                                            <div className="row">
                                                <div className="col-12 overflow-auto">
                                                    <DealerInfoTable
                                                        data={
                                                            modalres.detail
                                                                .contract_numbers_info
                                                        }
                                                        changeUpline={
                                                            changeUpline
                                                        }
                                                        activeEkyc={activeEkyc}
                                                        syncDealer={syncDealer}
                                                        activeSim={activeSim}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <div className="col-12 mt-5">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="bg-light rounded-lg d-flex flex-column p-3">
                                                        <h3 className="col-12">
                                                            แก้ไขข้อมูล
                                                        </h3>
                                                        <div className="p-2 d-flex row">
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    ชื่อดีลเลอร์
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <input
                                                                        className="form-control rounded-lg"
                                                                        type="text"
                                                                        value={
                                                                            editname
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditName(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    {isNumeric(
                                                                        modalres
                                                                            .detail
                                                                            .personal_info
                                                                            .personal_id
                                                                    )
                                                                        ? "หมายเลขบัตรประจำตัวประชาชน"
                                                                        : "หมายเลขพาสปอร์ต"}
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <input
                                                                        className="form-control rounded-lg"
                                                                        type="text"
                                                                        value={
                                                                            editcitizenid
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditCitizenid(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    เบอร์ติดต่อกลับ
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <input
                                                                        className="form-control rounded-lg"
                                                                        type="text"
                                                                        value={
                                                                            editcontactnumber
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditContactNumber(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    อีเมล
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <input
                                                                        className="form-control rounded-lg"
                                                                        type="text"
                                                                        value={
                                                                            editemail
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditEmail(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    ภาค
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent,
                                                                        }}
                                                                        noOptionsMessage={() =>
                                                                            "ไม่พบข้อมูล"
                                                                        }
                                                                        value={
                                                                            editgeo
                                                                        }
                                                                        options={
                                                                            initaddress.geo
                                                                        }
                                                                        placeholder="เลือก"
                                                                        onChange={
                                                                            selectGeo
                                                                        }
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        menuPortalTarget={
                                                                            document.body
                                                                        }
                                                                        styles={
                                                                            customStyles
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    จังหวัด
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent,
                                                                        }}
                                                                        noOptionsMessage={() =>
                                                                            "ไม่พบข้อมูล"
                                                                        }
                                                                        value={
                                                                            editprovince
                                                                        }
                                                                        options={
                                                                            mainprovince
                                                                        }
                                                                        placeholder="เลือก"
                                                                        onChange={
                                                                            selectProvince
                                                                        }
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        menuPortalTarget={
                                                                            document.body
                                                                        }
                                                                        styles={
                                                                            customStyles
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    เขต/อำเภอ
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent,
                                                                        }}
                                                                        noOptionsMessage={() =>
                                                                            "ไม่พบข้อมูล"
                                                                        }
                                                                        value={
                                                                            editdistrict
                                                                        }
                                                                        options={
                                                                            maindistrict
                                                                        }
                                                                        placeholder="เลือก"
                                                                        onChange={
                                                                            selectDistrict
                                                                        }
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        menuPortalTarget={
                                                                            document.body
                                                                        }
                                                                        styles={
                                                                            customStyles
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    แขวง/ตำบล
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent,
                                                                        }}
                                                                        noOptionsMessage={() =>
                                                                            "ไม่พบข้อมูล"
                                                                        }
                                                                        value={
                                                                            editsubdistrict
                                                                        }
                                                                        options={
                                                                            mainsubdistrict
                                                                        }
                                                                        placeholder="เลือก"
                                                                        onChange={
                                                                            selectSubDistrict
                                                                        }
                                                                        isClearable={
                                                                            true
                                                                        }
                                                                        menuPortalTarget={
                                                                            document.body
                                                                        }
                                                                        styles={
                                                                            customStyles
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    รหัสไปรษณีย์
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <input
                                                                        className="form-control rounded-lg"
                                                                        type="text"
                                                                        value={
                                                                            editzipcode
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditZipcode(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    ที่อยู่
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <TextareaAutosize
                                                                        className="form-control rounded-lg min-h-100px"
                                                                        maxLength="255"
                                                                        value={
                                                                            editaddress
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditAddress(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    ธนาคาร
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <Select
                                                                        components={{
                                                                            Control: ControlComponent,
                                                                        }}
                                                                        noOptionsMessage={() =>
                                                                            "ไม่พบข้อมูล"
                                                                        }
                                                                        value={
                                                                            editbank
                                                                        }
                                                                        options={
                                                                            banklist
                                                                        }
                                                                        placeholder="เลือก"
                                                                        onChange={
                                                                            selectBank
                                                                        }
                                                                        // isClearable={
                                                                        //     true
                                                                        // }
                                                                        menuPortalTarget={
                                                                            document.body
                                                                        }
                                                                        styles={
                                                                            customStyles
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    ชื่อบัญชีธนาคาร
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <input
                                                                        className="form-control rounded-lg"
                                                                        type="text"
                                                                        value={
                                                                            editaccountname
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditAccountname(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row col-12">
                                                                <label className="col-xl-3 col-12 col-form-label">
                                                                    เลขบัญชีธนาคาร
                                                                </label>
                                                                <div className="col-xl-9 col-12">
                                                                    <input
                                                                        className="form-control rounded-lg"
                                                                        type="text"
                                                                        value={
                                                                            editaccountnumber
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditAccountnumber(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between border-top col-12 mt-5 pt-10">
                                                                <div className="mr-2"></div>
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        onClick={
                                                                            updateInfo
                                                                        }
                                                                        className="btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4"
                                                                    >
                                                                        แก้ไข
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </>
                    ) : (
                        <div className="col-xl-12 text-center m-0 my-25 p-0">
                            <i className="fas fa-exclamation-circle fa-5x mb-2 text-danger"></i>
                            <h4 className="mt-3">
                                ไม่พบรายละเอียดของดีลเลอร์นี้
                            </h4>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}
