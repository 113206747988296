import React, { useEffect } from "react";

import $ from "jquery";

export function PositionTable(prop) {
    useEffect(() => {
        $(document).ready(function () {
            $("#kt_datatable").DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: 10,
                responsive: true,
                dom: "Bfrtip",
                buttons: ["excel"],
                order: [[4, "asc"]],
                language: {
                    paginate: {
                        previous: "ก่อนหน้า",
                        next: "ถัดไป",
                    },
                    info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                    infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                    zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                },
                oLanguage: {
                    sSearch: "คำสำคัญ",
                },
                columns: [
                    {
                        title: "ชื่อ - นามสกุล",
                        data: function (value) {
                            if (
                                value.name !== null &&
                                value.name !== ""
                            ) {
                                return value.name;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "เบอร์ซิมปันสุข",
                        data: function (value) {
                            if (
                                value.phonenumber !== null &&
                                value.phonenumber !== ""
                            ) {
                                return value.phonenumber;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "เบอร์ติดต่อ",
                        data: function (value) {
                            if (
                                value.contact_number !== null &&
                                value.contact_number !== ""
                            ) {
                                return value.contact_number;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "ตำแหน่งปัจจุบัน",
                        className: "dt-body-center",
                        data: function (value) {
                            if (value.position === "E") {
                                return '<span class="label label-info label-pill label-inline mx-auto my-auto">Elite Dealer</span>';
                            } else if (value.position === "SS") {
                                return '<span class="label label-warning label-pill label-inline mx-auto my-auto">Super Star Dealer</span>';
                            } else if (value.position === "S") {
                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">Star Dealer</span>';
                            } else if (value.position === "B") {
                                return '<span class="label label-dark label-pill label-inline mx-auto my-auto">Basic Dealer</span>';
                            } else {
                                return '<span class="label label-primary label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                            }
                        },
                    },
                    {
                        title: "วันที่ปรับตำแหน่ง",
                        // className: "dt-body-center",
                        data: function (value) {
                            if (
                                value.pos_date !== null &&
                                value.pos_date !== ""
                            ) {
                                return value.pos_date;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "วันที่รักษาสิทธิ์",
                        // className: "dt-body-center",
                        data: function (value) {
                            if (
                                value.preserve_date !== null &&
                                value.preserve_date !== ""
                            ) {
                                return value.preserve_date;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "จำนวนดีลเลอร์ภายใต้องค์กร",
                        // className: "dt-body-center",
                        data: function (value) {
                            if (
                                value.dealer_count !== null &&
                                value.dealer_count !== ""
                            ) {
                                return value.dealer_count;
                            } else {
                                return "-";
                            }
                        },
                    },
                ],
            });
        });
    }, []);

    return (
        <table
            id="kt_datatable"
            className="table table-striped table-bordered"
        ></table>
    );
}