import React, { useEffect } from "react";

import $ from "jquery";

export function OrderTable(prop) {
    useEffect(() => {
        $(document).ready(function () {
            $("#kt_datatable").DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: -1,
                responsive: true,
                dom: "Bfrtip",
                buttons: ["excel"],
                order: [[2, "asc"]],
                language: {
                    paginate: {
                        previous: "ก่อนหน้า",
                        next: "ถัดไป",
                    },
                    info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                    infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                    zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                },
                oLanguage: {
                    sSearch: "คำสำคัญ",
                },
                columns: [
                    {
                        title: "ดูรายละเอียด",
                        className: "dt-body-center",
                        data: null,
                        render: function (data, type, row) {
                            if (data.status === "pending") {
                                return `<button
                                            type="button"
                                            class="btn btn-icon btn-primary view-order-info"
                                            data-ordernumber=${data.order_id}
                                            >
                                                <i class="fa fa-search"></i>
                                        </button>`;
                            } else {
                                return "";
                            }
                        },
                    },
                    {
                        title: "หมายเลขคำสั่งซื้อ",
                        data: "order_id",
                    },
                    {
                        title: "วันที่สั่งซื้อ",
                        data: "create_date",
                    },
                    {
                        title: "ประเภทรายการ",
                        className: "dt-body-center",
                        data: function (value) {
                            if (value.type === "A") {
                                return '<span class="badge badge-success mx-auto my-auto rounded-lg">สมัครดีลเลอร์/เพิ่มสิทธิ</span>';
                            } else if (value.type === "Q") {
                                return '<span class="badge badge-info mx-auto my-auto rounded-lg">เติมเงิน/สมัครแพ็กเกจ</span>';
                            } else {
                                return "";
                            }
                        },
                    },
                    {
                        title: "หมายเลข",
                        data: function (value) {
                            if (value.number !== null && value.number !== "") {
                                return value.number;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "สถานะคำสั่งซื้อ",
                        className: "dt-body-center",
                        data: function (value) {
                            if (value.status === "cancel") {
                                return '<span class="badge badge-danger mx-auto my-auto rounded-lg">หมดอายุ</span>';
                            } else if (value.status === "paid") {
                                return '<span class="badge badge-success mx-auto my-auto rounded-lg">ชำระเรียบร้อยแล้ว</span>';
                            } else if (value.status === "pending") {
                                // if (
                                //     value.payment === "QR-Code" &&
                                //     value.qrcode === ""
                                // ) {
                                //     return '<span class="badge badge-warning mx-auto my-auto">มีปัญหา</span>';
                                // } else {
                                return '<span class="badge badge-secondary mx-auto my-auto rounded-lg">รอการชำระเงิน</span>';
                                // }
                            }
                        },
                    },
                    {
                        title: "ราคา",
                        data: "total",
                    },
                ],
            });
        });
    }, []);

    useEffect(() => {
        $(document).ready(function () {
            $(".view-order-info").click(function () {
                console.log($(this).attr("data-ordernumber"));
                // if ($(this).attr("data-qrcode").includes("base64,")) {
                //     prop.setQrcodeType("base64");
                // }
                // prop.setOrdernumber($(this).attr("data-order"));
                // prop.setQrcode($(this).attr("data-qrcode"));
                // prop.setPrice($(this).attr("data-price"));
                // prop.setPayment($(this).attr("data-payment"));
                // prop.setOrdernumber($(this).attr("data-ordernumber"));
                prop.getOrderInfo($(this).attr("data-ordernumber"));
            });
        });
    });

    return (
        <table
            id="kt_datatable"
            className="table table-striped table-bordered"
        ></table>
    );
}
