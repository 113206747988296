import React, { useEffect } from "react";

import $ from "jquery";

export function SalevatTable(prop) {
    useEffect(() => {
        $(document).ready(function () {
            $("#kt_datatable").DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: 10,
                responsive: true,
                dom: "Bfrtip",
                buttons: ["excel"],
                order: [[0, "asc"]],
                language: {
                    paginate: {
                        previous: "ก่อนหน้า",
                        next: "ถัดไป",
                    },
                    info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                    infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                    zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                },
                oLanguage: {
                    sSearch: "คำสำคัญ",
                },
                columns:
                    prop.reporttype === "1"
                        ? [
                              {
                                  title: "#",
                                  //   className: "dt-body-center",
                                  data: "no",
                              },
                              {
                                  title: "หมายเลขประจำตัวผู้เสัยภาษี",
                                  data: "id_card",
                              },
                              {
                                  title: "คำนำหน้า",
                                  data: function (value) {
                                      if (value.title !== "") {
                                          return value.title;
                                      } else {
                                          return "-";
                                      }
                                  },
                              },
                              {
                                  title: "ชื่อ - นามสกุล",
                                  data: "name",
                              },
                              {
                                  title: "ที่อยู่",
                                  data: "address",
                              },
                              {
                                  title: "วันที่",
                                  data: "date",
                              },
                              {
                                  title: "ราคา",
                                  data: "excluding_vat",
                              },
                              {
                                  title: "ภาษี",
                                  data: "vat",
                              },
                              {
                                  title: "ค่าบริการ",
                                  data: "fee",
                              },
                              {
                                  title: "ราคาสุทธิ",
                                  data: "sale",
                              },
                          ]
                        : [
                              {
                                  title: "#",
                                  //   className: "dt-body-center",
                                  data: "no",
                              },
                              {
                                  title: "หมายเลขประจำตัวผู้เสัยภาษี",
                                  data: "id_card",
                              },
                              {
                                  title: "คำนำหน้า",
                                  data: function (value) {
                                      if (value.title !== "") {
                                          return value.title;
                                      } else {
                                          return "-";
                                      }
                                  },
                              },
                              {
                                  title: "ชื่อ - นามสกุล",
                                  data: "name",
                              },
                              {
                                  title: "ที่อยู่",
                                  data: "address",
                              },
                              {
                                  title: "วันที่",
                                  data: "date",
                              },
                              {
                                  title: "ราคา",
                                  data: "excluding_vat",
                              },
                              {
                                  title: "ภาษี",
                                  data: "vat",
                              },
                              {
                                  title: "ค่าบริการ",
                                  data: "fee",
                              },
                              {
                                  title: "ราคาสุทธิ",
                                  data: "sale",
                              },
                              {
                                  title: "รหัสสินค้า",
                                  data: "pcode",
                              },
                              {
                                  title: "รายละเอียด",
                                  data: "pdesc",
                              },
                          ],
            });
        });
    }, []);

    return (
        <table
            id="kt_datatable"
            className="table table-striped table-bordered"
        ></table>
    );
}
