import React, { useEffect, useState, useRef } from "react";

import { Modal } from "react-bootstrap";

import Moment from "moment";

import NumberFormat from "react-number-format";

import QRCodeReact from "qrcode.react";

import DatePicker, { utils } from "react-modern-calendar-datepicker";

import { CSVLink } from "react-csv";

import { OrderTable } from "./Table";

import config from "../../../config";

const myCustomLocale = {
    // months list by order
    months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ],
    // week days by order
    weekDays: [
        {
            name: "อาทิตย์", // used for accessibility
            short: "อา", // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: "จันทร์",
            short: "จ",
        },
        {
            name: "อังคาร",
            short: "อ",
        },
        {
            name: "พุธ",
            short: "พ",
        },
        {
            name: "พฤหัสบดี",
            short: "พฤ",
        },
        {
            name: "ศุกร์",
            short: "ศ",
        },
        {
            name: "เสาร์",
            short: "ส",
            isWeekend: true,
        },
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: "Next Month", // texts in the date picker
    previousMonth: "Previous Month", // texts in the date picker
    openMonthSelector: "Open Month Selector", // texts in the date picker
    openYearSelector: "Open Year Selector", // texts in the date picker
    closeMonthSelector: "Close Month Selector", // texts in the date picker
    closeYearSelector: "Close Year Selector", // texts in the date picker
    defaultPlaceholder: "Select...", // texts in the date picker
    from: "from", // for input range value
    to: "to", // for input range value
    digitSeparator: ",", // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false, // is your language rtl or ltr?
};

const minimumDate = {
    year: 2020,
    month: 12,
    day: 31,
};

export function OrderReport() {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({});
    const [showModal, setShow] = useState(false);
    const [modalres, setModalResponse] = useState({});
    const [dataset, setDataset] = useState([]);

    const [name, setName] = useState("");
    const [date, setDate] = useState({
        from: null,
        to: null,
    });
    const [ordernumber, setOrdernumber] = useState("");
    const [citizenid, setCitizenid] = useState("");
    const [phonenumber, setPhonenumber] = useState("");

    const refqrcode = useRef(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to
                    ? date.from.year +
                      "/" +
                      ("0" + date.from.month).slice(-2) +
                      "/" +
                      ("0" + date.from.day).slice(-2) +
                      " - " +
                      date.to.year +
                      "/" +
                      ("0" + date.to.month).slice(-2) +
                      "/" +
                      ("0" + date.to.day).slice(-2)
                    : ""
            }
            className="form-control rounded-lg"
            type="text"
            id="dateperiod"
            name="dateperiod"
        />
    );
    useEffect(() => {
        let postparam = {
            filter: { start: Moment().format("YYYY-MM-DD") },
        };
        getOrder(postparam);
    }, []);

    const download = (filename) => {
        const canvas: any = document.querySelector(".qrimg > canvas");
        refqrcode.current.href = canvas.toDataURL();
        refqrcode.current.download = `${filename}.png`;
    };

    const handleSearch = () => {
        let param = {};
        let postparam = {};
        if (name !== "") {
            param["name"] = name;
        }
        if (ordernumber !== "") {
            param["bill_no"] = ordernumber;
        }
        if (citizenid !== "") {
            param["id_card"] = citizenid;
        }
        if (phonenumber !== "") {
            param["number"] = phonenumber;
        }
        if (date.from !== null && date.to !== null) {
            param["start"] =
                date.from.year +
                "-" +
                ("0" + date.from.month).slice(-2) +
                "-" +
                ("0" + date.from.day).slice(-2);
            param["end"] =
                date.to.year +
                "-" +
                ("0" + date.to.month).slice(-2) +
                "-" +
                ("0" + date.to.day).slice(-2);
        }
        if (param) {
            postparam["filter"] = param;
        }
        setLoading(true);
        getOrder(postparam);
    };

    const getOrder = (postparam) => {
        setResponse({});
        setDataset({});
        fetch(config.url + "admin/order/lists", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
                if (Object.keys(result).length > 0) {
                    if (result.responsecode === "00") {
                        let array = [];
                        for (let prop in result.lists) {
                            let objectdata = {
                                status:
                                    result.lists[prop]["status"] === "pending"
                                        ? "รอการชำระเงิน"
                                        : result.lists[prop]["status"] ===
                                          "paid"
                                        ? "ชำระเรียบร้อยแล้ว"
                                        : "หมดอายุ",
                                order_id: result.lists[prop]["order_id"],
                                dealer_id: result.lists[prop]["dealer_id"],
                                mcode: result.lists[prop]["mcode"],
                                name: result.lists[prop]["name"],
                                // number:
                                //     result.lists[prop]["number"].slice(0, 3) +
                                //     "-" +
                                //     result.lists[prop]["number"].slice(3, 6) +
                                //     "-" +
                                //     result.lists[prop]["number"].slice(6),
                                number: result.lists[prop]["number"],
                                // number_contact:
                                //     result.lists[prop]["number_contact"].slice(
                                //         0,
                                //         3
                                //     ) +
                                //     "-" +
                                //     result.lists[prop]["number_contact"].slice(
                                //         3,
                                //         6
                                //     ) +
                                //     "-" +
                                //     result.lists[prop]["number_contact"].slice(
                                //         6
                                //     ),
                                number_contact:
                                    result.lists[prop]["number_contact"],
                                total: result.lists[prop]["total"],
                                type: result.lists[prop]["type"],
                                paid_date: result.lists[prop]["paid_date"],
                                create_date: result.lists[prop]["create_date"],
                            };
                            array.push(objectdata);
                        }
                        console.log(array);
                        setDataset(array);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const getOrderInfo = (ordernumber) => {
        setLoading(true);
        setModalResponse({});
        fetch(config.url + "admin/order/info", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                order_id: ordernumber,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setModalResponse(result);
                handleShow();
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const Excelheaders = [
        { label: "เลขคำสั่งซื้อ", key: "order_id" },
        { label: "เบอร์", key: "number" },
        { label: "สถานะ", key: "status" },
        { label: "วันที่สั่งซื้อ", key: "create_date" },
        { label: "ยอดรวม", key: "total" },
    ];
    return (
        <>
            {loading ? (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-custom card-stretch gutter-b rounded-lg">
                            <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fa fa-search text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        ค้นหา
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="ordername">
                                            ชื่อผู้สั่งซื้อ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="ordername"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="orderdate">
                                            วันที่สั่งซื้อ
                                        </label>
                                        <DatePicker
                                            value={date}
                                            onChange={setDate}
                                            renderInput={renderCustomInput}
                                            minimumDate={minimumDate}
                                            maximumDate={utils().getToday()}
                                            locale={myCustomLocale}
                                            wrapperClassName="col-12 p-0"
                                            calendarClassName="responsive-calendar"
                                            calendarPopperPosition="bottom"
                                            shouldHighlightWeekends
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="ordernumber">
                                            หมายเลขคำสั่งซื้อ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="ordernumber"
                                            value={ordernumber}
                                            onChange={(e) =>
                                                setOrdernumber(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="citizenid">
                                            หมายเลขบัตรประชาชนของผู้สั่งซื้อ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="citizenid"
                                            value={citizenid}
                                            onChange={(e) =>
                                                setCitizenid(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="phonenumber">
                                            เบอร์โทรศัพท์ที่สั่งซื้อ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="phonenumber"
                                            value={phonenumber}
                                            onChange={(e) =>
                                                setPhonenumber(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button
                                    type="button"
                                    onClick={handleSearch}
                                    className="btn btn-sm btn-primary rounded-lg"
                                >
                                    <i className="far fa-dot-circle"></i> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="card card-custom card-stretch gutter-b rounded-lg">
                            <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                <div className="card-title">
                                    <h3 className="card-label text-white">
                                        รายงาน
                                    </h3>
                                </div>
                                <div className="card-toolbar">
                                    {/* จำนวนผลลัพท์{" "}
                                    {Object.keys(response).length > 0
                                        ? response.responsecode === "00"
                                            ? Object.keys(response.lists).length
                                            : null
                                        : null}{" "}
                                    รายการ */}
                                    {Object.keys(response).length > 0 ? (
                                        response.responsecode === "00" ? (
                                            dataset.length > 0 ? (
                                                <CSVLink
                                                    data={dataset}
                                                    headers={Excelheaders}
                                                    className="btn btn-secondary rounded-lg d-none d-md-block"
                                                    target="_blank"
                                                >
                                                    ดาวน์โหลด Excel
                                                </CSVLink>
                                            ) : null
                                        ) : null
                                    ) : null}
                                    {/* <DropdownButton
                                        alignRight
                                        title="คำสั่งต่างๆ"
                                        variant="secondary"
                                        onSelect={handleDropdown}
                                        className="rounded-lg"
                                    >
                                        <Dropdown.Item eventKey="download">
                                            <CSVLink
                                                data={Exceldata}
                                                headers={Excelheaders}
                                            >
                                                Download me
                                            </CSVLink>
                                            ดาวน์โหลด Excel
                                        </Dropdown.Item>
                                    </DropdownButton> */}
                                </div>
                            </div>
                            <div className="card-body">
                                {Object.keys(response).length > 0 ? (
                                    <>
                                        {response.responsecode === "00" ? (
                                            <OrderTable
                                                data={response.lists}
                                                getOrderInfo={getOrderInfo}
                                            />
                                        ) : (
                                            <div className="col-xl-12 text-center m-0 my-25 p-0">
                                                <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                                                <h4 className="mt-3">
                                                    ไม่พบข้อมูลที่ต้องการค้นหา
                                                </h4>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="col-xl-12 text-center m-0 my-25 p-0">
                                        <i className="fas fa-exclamation-circle fa-5x mb-2 text-danger"></i>
                                        <h4 className="mt-3">
                                            ไม่สามารถดึงข้อมูลได้
                                            กรุณาลองใหม่อีกครั้ง
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                centered
                aria-labelledby="unactive-number-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="unactive-number-modal"
                        className="col-11 p-0"
                    >
                        ข้อมูลเพิ่มเติม
                    </Modal.Title>
                    <button
                        type="button"
                        className="close col-1 text-right pr-3"
                        onClick={handleClose}
                    >
                        <i className="ki ki-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center pb-5">
                        {modalres.responsecode === "00" ? (
                            <>
                                <div className="col-12">
                                    <div className="row justify-content-center">
                                        <div className="text-center rounded-lg bg-primary p-1">
                                            <div className="text-white text-center">
                                                หมายเลขคำสั่งซื้อ{" "}
                                                {modalres.header.order_id}
                                            </div>
                                            {/* {payment === "QR-Code" ? ( */}
                                            <div className="qrimg">
                                                {modalres.header.qr_type ===
                                                "TEXT" ? (
                                                    <QRCodeReact
                                                        value={
                                                            modalres.header
                                                                .qr_code
                                                        }
                                                        size={256}
                                                        id="qrcode"
                                                        includeMargin={true}
                                                    />
                                                ) : (
                                                    <img
                                                        alt="qrcode"
                                                        src={
                                                            modalres.header
                                                                .qr_code
                                                        }
                                                        className="m-1"
                                                    />
                                                )}
                                            </div>
                                            {/* ) : (
                                            <div className="wallet">
                                                <img
                                                    alt="qrcode"
                                                    src="https://cdn.simk4.com/payment/wallet.png"
                                                    className="m-1 max-h-250px"
                                                />
                                            </div>
                                        )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-5">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="bg-light rounded-lg d-flex flex-column p-3">
                                                <div className="p-2 d-flex">
                                                    <div className="col-7">
                                                        เบอร์โทรศัพท์ที่สั่งซื้อ
                                                    </div>
                                                    <div className="ml-auto text-right">
                                                        {modalres.header.number}
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-7">
                                                        ชื่อลูกค้า
                                                    </div>
                                                    <div className="ml-auto text-right">
                                                        {modalres.header.name}
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-7">
                                                        เลขประจำตัวผู้เสียภาษี
                                                    </div>
                                                    <div className="ml-auto text-right">
                                                        {
                                                            modalres.header
                                                                .id_card
                                                        }
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-3">
                                                        ที่อยู่จัดส่ง
                                                    </div>
                                                    <div className="ml-auto text-right">
                                                        {modalres.header
                                                            .shipping.address +
                                                            " " +
                                                            modalres.header
                                                                .shipping
                                                                .amphur +
                                                            " " +
                                                            modalres.header
                                                                .shipping
                                                                .district +
                                                            modalres.header
                                                                .shipping
                                                                .province}
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-7">
                                                        เบอร์ติดต่อ
                                                    </div>
                                                    <div className="ml-auto text-right">
                                                        {
                                                            modalres.header
                                                                .shipping
                                                                .contact_number
                                                        }
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-8">
                                                        สถานะคำสั่งซื้อ
                                                    </div>
                                                    <div className="ml-auto">
                                                        {modalres.header
                                                            .status ===
                                                        "pending" ? (
                                                            <span
                                                                className={`badge badge-secondary mx-auto my-auto rounded-lg`}
                                                            >
                                                                รอการชำระเงิน
                                                            </span>
                                                        ) : modalres.header
                                                              .status ===
                                                          "paid" ? (
                                                            <span className="badge badge-success mx-auto my-auto rounded-lg">
                                                                ชำระเรียบร้อยแล้ว
                                                            </span>
                                                        ) : (
                                                            <span className="badge badge-danger mx-auto my-auto rounded-lg">
                                                                หมดอายุ
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-7">
                                                        วันที่สั่งซื้อ
                                                    </div>
                                                    <div className="ml-auto text-right">
                                                        {
                                                            modalres.header
                                                                .create_date
                                                        }
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-7">
                                                        วันที่ชำระเงิน
                                                    </div>
                                                    <div className="ml-auto text-right">
                                                        {
                                                            modalres.header
                                                                .paid_date
                                                        }
                                                    </div>
                                                </div>
                                                <div className="border-top px-4 mx-3" />
                                                <div className="p-2 d-flex pt-3">
                                                    <div className="col-8">
                                                        <b>ยอดรวม</b>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <b className="green">
                                                            <NumberFormat
                                                                value={parseFloat(
                                                                    modalres
                                                                        .header
                                                                        .total
                                                                )}
                                                                displayType={
                                                                    "text"
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                suffix={" บาท"}
                                                            />
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="m-3">
                                                    <a
                                                        href={"#qrcode"}
                                                        ref={refqrcode}
                                                        className={`btn btn-success ${
                                                            modalres.header
                                                                .status ===
                                                            "pending"
                                                                ? ""
                                                                : "disabled"
                                                        } btn-block font-weight-bold text-uppercase px-6 py-2 rounded-lg`}
                                                        onClick={() => {
                                                            if (
                                                                modalres.header
                                                                    .status ===
                                                                "pending"
                                                            ) {
                                                                download(
                                                                    modalres
                                                                        .header
                                                                        .order_id
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        }}
                                                    >
                                                        บันทึกภาพ
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <h5 className="text-center text-color-main mb-0">
                                สถานะคำสั่งซื้อ{" "}
                                {orderstatus === "pending" ? (
                                    <span
                                        className={`badge badge-${
                                            payment === "QR-Code" &&
                                            qrcode === ""
                                                ? "warning"
                                                : "secondary"
                                        } mx-auto my-auto`}
                                    >
                                        {payment === "QR-Code" && qrcode === ""
                                            ? "มีปัญหา"
                                            : "รอการชำระเงิน"}
                                    </span>
                                ) : orderstatus === "paid" ? (
                                    <span className="badge badge-success mx-auto my-auto">
                                        ชำระเรียบร้อยแล้ว
                                    </span>
                                ) : (
                                    <span className="badge badge-danger mx-auto my-auto">
                                        หมดอายุ
                                    </span>
                                )}
                            </h5> */}
                                </div>
                            </>
                        ) : (
                            <div className="col-xl-12 text-center m-0 my-25 p-0">
                                <i className="fas fa-exclamation-circle fa-5x mb-2 text-danger"></i>
                                <h4 className="mt-3">
                                    {modalres.responsecode === "100"
                                        ? "ไม่พบรายละเอียดของคำสั่งซื้อนี้"
                                        : null}
                                </h4>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
