import React, { useEffect, useState } from "react";

import SVG from "react-inlinesvg";

import Moment from "moment";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import config from "../../config";

export function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({});
    useEffect(() => {
        let postparam = {
            start: Moment().format("YYYY-MM-DD"),
        };
        getDashboard(postparam);
    }, []);

    const getDashboard = (postparam) => {
        fetch(config.url + "admin/dashboard/summary", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setResponse(result);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };
    return (
        <>
            {loading ? (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {Object.keys(response).length > 0 ? (
                        <>
                            {response.responsecode === "0" ? (
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div
                                            className={`card card-custom card-stretch gutter-b rounded-lg`}
                                        >
                                            <div className="card-body d-flex flex-column p-0">
                                                <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                                                    <span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
                                                        <span className="symbol-label">
                                                            <span className="svg-icon svg-icon-xl svg-icon-primary">
                                                                <SVG
                                                                    title="จำนวนดีลเลอร์ที่สมัครใหม่หรือเพิ่มสิทธิในวันนี้"
                                                                    src={toAbsoluteUrl(
                                                                        "/media/svg/icons/Communication/Group.svg"
                                                                    )}
                                                                ></SVG>
                                                            </span>
                                                            {/* <i className="fas fa-users fa-2x text-primary"></i> */}
                                                        </span>
                                                    </span>
                                                    <div className="d-flex flex-column text-right">
                                                        <span className="text-dark-75 font-weight-bolder font-size-h3">
                                                            {
                                                                response.count
                                                                    .registered
                                                            }
                                                        </span>
                                                        <span className="text-muted font-weight-bold mt-2">
                                                            จำนวนดีลเลอร์ที่สมัครใหม่หรือเพิ่มสิทธิในวันนี้
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="separator separator-solid separator-border-4 mt-3 mb-3" />
                                                <div className="row card-spacer">
                                                    <div className="col-6 d-flex flex-column text-center border-right">
                                                        <span className="text-success font-weight-bolder font-size-h3">
                                                            {
                                                                response.count
                                                                    .success_dealer
                                                            }
                                                        </span>
                                                        <span className="text-muted font-weight-bold mt-2">
                                                            ชำระเงินแล้ว
                                                        </span>
                                                    </div>
                                                    <div className="col-6 d-flex flex-column text-center">
                                                        <span className="text-danger font-weight-bolder font-size-h3">
                                                            {
                                                                response.count
                                                                    .unsuccess_dealer
                                                            }
                                                        </span>
                                                        <span className="text-muted font-weight-bold mt-2">
                                                            ยังไม่ได้ชำระเงิน
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div
                                            className={`card card-custom card-stretch gutter-b rounded-lg`}
                                        >
                                            <div className="card-body d-flex flex-column p-0">
                                                <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                                                    <span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
                                                        <span className="symbol-label">
                                                            <span className="svg-icon svg-icon-xl svg-icon-primary">
                                                                <SVG
                                                                    title="จำนวนรายการเติมเงิน/สมัครแพ็กเกจในวันนี้"
                                                                    src={toAbsoluteUrl(
                                                                        "/media/svg/icons/Shopping/Bag2.svg"
                                                                    )}
                                                                ></SVG>
                                                            </span>
                                                            {/* <i className="fas fa-coins fa-2x text-primary"></i> */}
                                                        </span>
                                                    </span>
                                                    <div className="d-flex flex-column text-right">
                                                        <span className="text-dark-75 font-weight-bolder font-size-h3">
                                                            {response.count
                                                                .order -
                                                                response.count
                                                                    .registered}
                                                        </span>
                                                        <span className="text-muted font-weight-bold mt-2">
                                                            จำนวนรายการเติมเงิน/สมัครแพ็กเกจในวันนี้
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="separator separator-solid separator-border-4 mt-3 mb-3" />
                                                <div className="row card-spacer">
                                                    <div className="col-6 d-flex flex-column text-center border-right">
                                                        <span className="text-success font-weight-bolder font-size-h3">
                                                            {response.count
                                                                .invoice -
                                                                response.count
                                                                    .success_dealer}
                                                        </span>
                                                        <span className="text-muted font-weight-bold mt-2">
                                                            ชำระเงินแล้ว
                                                        </span>
                                                    </div>
                                                    <div className="col-6 d-flex flex-column text-center">
                                                        <span className="text-danger font-weight-bolder font-size-h3">
                                                            {response.count
                                                                .order -
                                                                response.count
                                                                    .registered -
                                                                (response.count
                                                                    .invoice -
                                                                    response
                                                                        .count
                                                                        .success_dealer)}
                                                        </span>
                                                        <span className="text-muted font-weight-bold mt-2">
                                                            ยังไม่ได้ชำระเงิน
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>failed</div>
                            )}
                        </>
                    ) : (
                        <div>catch error</div>
                    )}
                </>
            )}
        </>
    );
}
