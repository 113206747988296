import React, { useEffect } from 'react';

import $ from 'jquery';

export function ManageTable(prop) {
    useEffect(() => {
        $(document).ready(function() {
            $('#kt_datatable').DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: prop.status.value !== '0' ? -1 : 10,
                responsive: true,
                dom: 'Bfrtip',
                buttons: [ 'excel' ],
                // order: [[4, "asc"]],
                language: {
                    paginate: {
                        previous: 'ก่อนหน้า',
                        next: 'ถัดไป'
                    },
                    info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                    infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                    emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                    infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                    zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                },
                oLanguage: {
                    sSearch: 'คำสำคัญ'
                },
                columns:
                    prop.status.value !== '0'
                        ? [
                              {
                                  title: 'จัดการการจอง',
                                  className: 'dt-body-center',
                                  data: null,
                                  render: function(data, type, row) {
                                      // if (data.position === "") {
                                      return `<button
                                        type="button"
                                        class="btn btn-icon btn-primary unlock-btn"
                                        data-phonenumber=${data.number}
                                        data-status=${data.status}
                                        >
                                            <i class="fas fa-tools"></i>
                                    </button>`;
                                      // } else {
                                      //     return "";
                                      // }
                                  }
                              },
                              {
                                  title: 'เบอร์',
                                  data: function(value) {
                                      if (value.number !== null && value.number !== '') {
                                          return (
                                              value.number.slice(0, 3) +
                                              '-' +
                                              value.number.slice(3, 6) +
                                              '-' +
                                              value.number.slice(6)
                                          );
                                      } else {
                                          return '-';
                                      }
                                  }
                              },
                              {
                                  title: 'สถานะเบอร์',
                                  className: 'dt-body-center',
                                  data: function(value) {
                                      if (value.status === 'available') {
                                          return '<span class="label label-success label-pill label-inline mx-auto my-auto">พร้อมใช้งาน</span>';
                                      } else if (value.status === 'reservation') {
                                          return '<span class="label label-secondary label-pill label-inline mx-auto my-auto">ถูกจองอยู่</span>';
                                      } else if (value.status === 'n/a') {
                                          return '<span class="label label-warning label-pill label-inline mx-auto my-auto">ปิดการใช้งาน</span>';
                                      } else {
                                          return '<span class="label label-dark label-pill label-inline mx-auto my-auto">สถานะอื่น</span>';
                                      }
                                  }
                              }
                          ]
                        : [
                              {
                                  title: 'เบอร์',
                                  data: function(value) {
                                      if (value.number !== null && value.number !== '') {
                                          return (
                                              value.number.slice(0, 3) +
                                              '-' +
                                              value.number.slice(3, 6) +
                                              '-' +
                                              value.number.slice(6)
                                          );
                                      } else {
                                          return '-';
                                      }
                                  }
                              },
                              {
                                  title: 'สถานะเบอร์',
                                  className: 'dt-body-center',
                                  data: function(value) {
                                      if (value.status === 'available') {
                                          return '<span class="label label-success label-pill label-inline mx-auto my-auto">พร้อมใช้งาน</span>';
                                      } else if (value.status === 'reservation') {
                                          return '<span class="label label-secondary label-pill label-inline mx-auto my-auto">ถูกจองอยู่</span>';
                                      } else if (value.status === 'n/a') {
                                          return '<span class="label label-warning label-pill label-inline mx-auto my-auto">ปิดการใช้งาน</span>';
                                      } else {
                                          return '<span class="label label-dark label-pill label-inline mx-auto my-auto">สถานะอื่น</span>';
                                      }
                                  }
                              }
                          ]
            });
        });
    }, []);

    useEffect(() => {
        $(document).ready(function() {
            $('.unlock-btn').click(function() {
                prop.unlockNumber($(this).attr('data-phonenumber'), $(this).attr('data-status'));
            });
        });
    });

    return <table id='kt_datatable' className='table table-striped table-bordered' />;
}
