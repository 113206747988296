import React, { useState } from "react";

import { Dropdown } from "react-bootstrap";

import Moment from "moment";

import DatePicker, { utils } from "react-modern-calendar-datepicker";

import Swal from "sweetalert2/dist/sweetalert2.js";

import config from "../../../config";

import { CommissionTable } from "./Table";

const myCustomLocale = {
    // months list by order
    months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ],
    // week days by order
    weekDays: [
        {
            name: "อาทิตย์", // used for accessibility
            short: "อา", // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: "จันทร์",
            short: "จ",
        },
        {
            name: "อังคาร",
            short: "อ",
        },
        {
            name: "พุธ",
            short: "พ",
        },
        {
            name: "พฤหัสบดี",
            short: "พฤ",
        },
        {
            name: "ศุกร์",
            short: "ศ",
        },
        {
            name: "เสาร์",
            short: "ส",
            isWeekend: true,
        },
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: "Next Month", // texts in the date picker
    previousMonth: "Previous Month", // texts in the date picker
    openMonthSelector: "Open Month Selector", // texts in the date picker
    openYearSelector: "Open Year Selector", // texts in the date picker
    closeMonthSelector: "Close Month Selector", // texts in the date picker
    closeYearSelector: "Close Year Selector", // texts in the date picker
    defaultPlaceholder: "Select...", // texts in the date picker
    from: "from", // for input range value
    to: "to", // for input range value
    digitSeparator: ",", // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false, // is your language rtl or ltr?
};

const minimumDate = {
    year: 2020,
    month: 12,
    day: 31,
};

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton:
            "btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
        cancelButton:
            "btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
    },
    buttonsStyling: false,
});

export function CommissionReport() {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [response, setResponse] = useState({});

    const [citizenid, setCitizenid] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [date, setDate] = useState({
        from: null,
        to: null,
    });

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to
                    ? date.from.year +
                      "/" +
                      ("0" + date.from.month).slice(-2) +
                      "/" +
                      ("0" + date.from.day).slice(-2) +
                      " - " +
                      date.to.year +
                      "/" +
                      ("0" + date.to.month).slice(-2) +
                      "/" +
                      ("0" + date.to.day).slice(-2)
                    : ""
            }
            className="form-control rounded-lg"
            type="text"
            id="dateperiod"
            name="dateperiod"
        />
    );

    const handleSearch = () => {
        let param = {};
        if (citizenid !== "") {
            param["idcard"] = [citizenid];
        }
        if (phonenumber !== "") {
            param["phonenumbers"] = [phonenumber];
        }
        if (date.from !== null && date.to !== null) {
            param["start"] =
                date.from.year +
                "-" +
                ("0" + date.from.month).slice(-2) +
                "-" +
                ("0" + date.from.day).slice(-2);
            param["end"] =
                date.to.year +
                "-" +
                ("0" + date.to.month).slice(-2) +
                "-" +
                ("0" + date.to.day).slice(-2);
        } else {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกช่วงเวลาที่ต้องการดึงข้อมูล",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        setLoading(true);
        setSearch(true);
        getCommission(param);
    }

    const getCommission = (postparam) => {
        setResponse({});
        fetch(config.url + "admin/reports/summary-commission", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }

    const downloadExcelFile = () => {
        setLoading(true);
        let postparam = {
            output_type: "base64_excel",
        };
        if (citizenid !== "") {
            postparam["idcard"] = [citizenid];
        }
        if (phonenumber !== "") {
            postparam["phonenumbers"] = [phonenumber];
        }
        if (date.from !== null && date.to !== null) {
            postparam["start"] =
                date.from.year +
                "-" +
                ("0" + date.from.month).slice(-2) +
                "-" +
                ("0" + date.from.day).slice(-2);
            postparam["end"] =
                date.to.year +
                "-" +
                ("0" + date.to.month).slice(-2) +
                "-" +
                ("0" + date.to.day).slice(-2);
        } else {
            if (Object.keys(postparam).length === 1) {
                postparam["start"] = Moment().format("YYYY-MM-DD");
                postparam["end"] = Moment().format("YYYY-MM-DD");
            } else {
                postparam["start"] = "2020-01-01";
                postparam["end"] = Moment().format("YYYY-MM-DD");
            }
        }
        fetch(config.url + "admin/reports/summary-commission", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    var link = document.createElement("a");
                    document.body.appendChild(link);
                    link.setAttribute("type", "hidden");
                    link.href = "data:text/plain;base64," + result.base64;
                    link.download = `commission_${Moment().format("YYYY_MM_DD")}.xlsx`;
                    link.click();
                    document.body.removeChild(link);
                } else {
                    swalWithBootstrapButtons.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: "ไม่สามารถดึงข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    return (
        <>
            {loading ? (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-custom card-stretch gutter-b rounded-lg">
                            <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fa fa-search text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        ค้นหา
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                <div className="form-group col-sm-4">
                                        <label htmlFor="citizenid">
                                            หมายเลขประจำตัวดีลเลอร์ที่ต้องการดูค่าคอมมิชชั่น
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="citizenid"
                                            value={citizenid}
                                            onChange={(e) =>
                                                setCitizenid(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="phonenumber">
                                            เบอร์โทรศัพท์ที่ต้องการดูค่าคอมมิชชั่น
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="phonenumber"
                                            value={phonenumber}
                                            onChange={(e) =>
                                                setPhonenumber(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="commissiondate">
                                            วันที่ได้รับค่าคอมมิชชั่น
                                        </label>
                                        <DatePicker
                                            name="commissiondate"
                                            value={date}
                                            onChange={setDate}
                                            renderInput={renderCustomInput}
                                            minimumDate={minimumDate}
                                            maximumDate={utils().getToday()}
                                            locale={myCustomLocale}
                                            wrapperClassName="col-12 p-0"
                                            calendarClassName="responsive-calendar"
                                            calendarPopperPosition="bottom"
                                            shouldHighlightWeekends
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button
                                    type="button"
                                    onClick={handleSearch}
                                    className="btn btn-sm btn-primary rounded-lg"
                                >
                                    <i className="far fa-dot-circle"></i> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                    {search ? (
                        <div className="col-lg-12">
                            <div className="card card-custom card-stretch gutter-b rounded-lg">
                                <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                    <div className="card-title">
                                        <h3 className="card-label text-white">
                                            รายงาน
                                        </h3>
                                    </div>
                                    <div className="card-toolbar">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="secondary"
                                                className="rounded-lg"
                                            >
                                                คำสั่งต่างๆ
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={downloadExcelFile}
                                                >
                                                    ดาวน์โหลด Excel ไฟล์
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* จำนวนผลลัพท์{" "}
                                        {Object.keys(response).length > 0
                                            ? response.responsecode === "0"
                                                ? Object.keys(response.lists)
                                                      .length
                                                : "0"
                                            : "0"}{" "}
                                        รายการ */}
                                    </div>
                                </div>
                                <div className="card-body">
                                    {Object.keys(response).length > 0 ? (
                                        <>
                                            {response.responsecode === 0 ? (
                                                // <div>test</div>
                                                <CommissionTable
                                                    data={response.summary}
                                                />
                                            ) : (
                                                <div className="col-xl-12 text-center m-0 my-25 p-0">
                                                    <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                                                    <h4 className="mt-3">
                                                        ไม่พบข้อมูลที่ต้องการจัดการ
                                                    </h4>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="col-xl-12 text-center m-0 my-25 p-0">
                                            <i className="fas fa-exclamation-circle fa-5x mb-2 text-danger"></i>
                                            <h4 className="mt-3">
                                                ไม่สามารถดึงข้อมูลได้
                                                กรุณาลองใหม่อีกครั้ง
                                            </h4>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </>
    )
}