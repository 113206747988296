import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import Swal from "sweetalert2/dist/sweetalert2.js";

import $ from "jquery";

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton:
            "btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
        cancelButton:
            "btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
    },
    buttonsStyling: false,
});

export function DealerInfoTable(prop) {
    const [anumber, setAnumber] = useState("");
    const [bnumber, setBnumber] = useState("");
    const [showModal, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        $(document).ready(function () {
            $("#kt_datatable_tab").DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: -1,
                // responsive: true,
                dom: "Bfrtip",
                buttons: ["excel"],
                // order: [[0, "asc"]],
                language: {
                    paginate: {
                        previous: "ก่อนหน้า",
                        next: "ถัดไป",
                    },
                    info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                    infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                    zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                },
                oLanguage: {
                    sSearch: "คำสำคัญ",
                },
                columns: [
                    {
                        title: "เปลี่ยนผู้แนะนำ",
                        className: "dt-body-center",
                        data: null,
                        render: function (data, type, row) {
                            // if (data.position === "") {
                            return `<button
                                        type="button"
                                        class="btn btn-icon btn-primary action-change-upline"
                                        data-anumber=${data.number}
                                        >
                                            <i class="fas fa-pencil-alt"></i>
                                    </button>`;
                            // } else {
                            //     return "";
                            // }
                        },
                    },
                    {
                        title: "Sync ข้อมูลดีลเลอร์",
                        className: "dt-body-center",
                        data: null,
                        render: function (data, type, row) {
                            // if (data.position === "") {
                            return `<button
                                        type="button"
                                        class="btn btn-icon btn-primary action-sync-dealer"
                                        data-number=${data.number}
                                        >
                                            <i class="fas fa-sync-alt"></i>
                                    </button>`;
                            // } else {
                            //     return "";
                            // }
                        },
                    },
                    {
                        title: "การลงทะเบียนซิม",
                        className: "dt-body-center",
                        data: null,
                        render: function (data, type, row) {
                            // if (data.position === "") {
                            if (data.kyc === 1) {
                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">ลงทะเบียนซิมแล้ว</span>';
                            } else {
                                return `<button
                                        type="button"
                                        class="btn btn-icon btn-primary action-active-sim"
                                        data-number=${data.number}
                                        >
                                            <i class="fas fa-sim-card"></i>
                                    </button>`;
                            }
                            // } else {
                            //     return "";
                            // }
                        },
                    },
                    {
                        title: "เปิดใช้งานซิม",
                        className: "dt-body-center",
                        data: null,
                        render: function (data, type, row) {
                            // if (data.position === "") {
                            // if (data.kyc === 1) {
                            //     return '<span class="label label-success label-pill label-inline mx-auto my-auto">ลงทะเบียนซิมแล้ว</span>';
                            // } else {
                            return `<button
                                    type="button"
                                    class="btn btn-icon btn-primary action-fix-sim"
                                    data-number=${data.number}
                                    >
                                        <i class="fas fa-tools"></i>
                                </button>`;
                            // }
                            // } else {
                            //     return "";
                            // }
                        },
                    },
                    {
                        title: "เบอร์ที่ดีลเลอร์นี้เป็นเจ้าของ",
                        data: function (value) {
                            if (value.number !== null && value.number !== "") {
                                return value.number;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "ระดับผลงานปัจจุบัน",
                        className: "dt-body-center",
                        data: function (value) {
                            if (value.position === "SS") {
                                return '<span class="label label-warning label-pill label-inline mx-auto my-auto">Super Star Dealer</span>';
                            } else if (value.position === "S") {
                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">Star Dealer</span>';
                            } else if (value.position === "B") {
                                return '<span class="label label-dark label-pill label-inline mx-auto my-auto">Basic Dealer</span>';
                            } else {
                                return '<span class="label label-primary label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                            }
                        },
                    },
                    {
                        title: "ชื่อดีลเลอร์ที่แนะนำ",
                        // data: "name",
                        data: function (value) {
                            if (
                                value.organize.upline.name !== null &&
                                value.organize.upline.name !== ""
                            ) {
                                return value.organize.upline.name;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "เบอร์ที่แนะนำ",
                        data: function (value) {
                            if (
                                value.organize.upline.number !== null &&
                                value.organize.upline.number !== ""
                            ) {
                                return value.organize.upline.number;
                            } else {
                                return "-";
                            }
                        },
                    },
                    // {
                    //     title: "หมายเลขบัตรประชาชน",
                    //     data: function (value) {
                    //         if (
                    //             value.personal_id !== null &&
                    //             value.personal_id !== ""
                    //         ) {
                    //             return value.personal_id;
                    //         } else {
                    //             return "-";
                    //         }
                    //     },
                    // },
                    // {
                    //     title: "เบอร์ติดต่อกลับ",
                    //     data: function (value) {
                    //         if (
                    //             value.contact_number !== null &&
                    //             value.contact_number !== ""
                    //         ) {
                    //             return (
                    //                 value.contact_number.slice(0, 3) +
                    //                 "-" +
                    //                 value.contact_number.slice(3, 6) +
                    //                 "-" +
                    //                 value.contact_number.slice(6)
                    //             );
                    //         } else {
                    //             return "-";
                    //         }
                    //     },
                    // },
                    // {
                    //     title: "วันที่สมัคร",
                    //     data: "register_date",
                    // },
                    // {
                    //     title: "ระดับผลงานปัจจุบัน",
                    //     className: "dt-body-center",
                    //     data: function (value) {
                    //         if (value.status === "SS") {
                    //             return '<span class="label label-warning label-pill label-inline mx-auto my-auto">Super Star Dealer</span>';
                    //         } else if (value.status === "S") {
                    //             return '<span class="label label-success label-pill label-inline mx-auto my-auto">Star Dealer</span>';
                    //         } else if (value.status === "B") {
                    //             return '<span class="label label-dark label-pill label-inline mx-auto my-auto">Basic Dealer</span>';
                    //         } else {
                    //             return '<span class="label label-primary label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                    //         }
                    //     },
                    // },
                ],
            });

            $("#kt_datatable_tab").addClass("w-100");
        });
    }, []);

    useEffect(() => {
        $(document).ready(function () {
            $(".action-change-upline").click(function () {
                setAnumber($(this).attr("data-anumber"));
                handleShow();
            });

            $(".action-sync-dealer").click(function () {
                let postparam = {
                    phonenumber: $(this).attr("data-number"),
                };
                prop.syncDealer(postparam)
            });

            $(".action-fix-sim").click(function () {
                let postparam = {
                    phonenumber: $(this).attr("data-number"),
                };
                prop.activeSim(postparam)
            });

            $(".action-active-sim").click(function () {
                // setAnumber($(this).attr("data-number"));
                // handleShow();
                swalWithBootstrapButtons
                    .fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            "<div>ต้องการปรับสถานะการลงทะเบียนออนไลน์ เป็นลงทะเบียนแล้วใช่หรือไม่?</div>",
                        icon: "question",
                        reverseButtons: true,
                        showCancelButton: true,
                        confirmButtonText: "ยืนยัน",
                        cancelButtonText: "ยกเลิก",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            let postparam = {
                                phonenumber: $(this).attr("data-number"),
                            };
                            prop.activeEkyc(postparam);
                        }
                    });
            });
        });
    }, []);

    const handleChange = () => {
        let postparam = {
            phonenumber: anumber,
            upline_phonenumber: bnumber,
        };
        prop.changeUpline(postparam);
    };

    return (
        <>
            <table
                id="kt_datatable_tab"
                className="table table-striped table-bordered"
            ></table>

            <Modal
                show={showModal}
                onHide={handleClose}
                // size="xl"
                centered
                aria-labelledby="unactive-number-modal"
            >
                <Modal.Header closeButton className="bg-secondary">
                    <Modal.Title
                        id="unactive-number-modal"
                        className="col-11 p-0"
                    >
                        เปลี่ยนผู้แนะนำ
                    </Modal.Title>
                    <button
                        type="button"
                        className="close col-1 text-right pr-3"
                        onClick={handleClose}
                    >
                        <i className="ki ki-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group col-12">
                                <label htmlFor="bnumber">
                                    เบอร์ของผู้แนะนำใหม่
                                </label>
                                <input
                                    type="text"
                                    className="form-control rounded-lg"
                                    name="bnumber"
                                    value={bnumber}
                                    onChange={(e) => setBnumber(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-secondary">
                    <div className="row col-12 p-0 m-0">
                        <div className="d-flex justify-content-between col-12">
                            <div className="mr-2"></div>
                            <div>
                                <button
                                    type="button"
                                    onClick={handleChange}
                                    className="btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4"
                                >
                                    <i className="far fa-dot-circle"></i>{" "}
                                    เปลี่ยนแปลง
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
