import React, { useEffect } from "react";

import $ from "jquery";

export function DealerTable(prop) {
    useEffect(() => {
        $(document).ready(function () {
            $("#kt_datatable").DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: -1,
                responsive: true,
                dom: "Bfrtip",
                buttons: ["excel"],
                order: [[4, "asc"]],
                language: {
                    paginate: {
                        previous: "ก่อนหน้า",
                        next: "ถัดไป",
                    },
                    info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                    infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                    zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                },
                oLanguage: {
                    sSearch: "คำสำคัญ",
                },
                columns: [
                    {
                        title: "ดูรายละเอียด",
                        className: "dt-body-center",
                        data: null,
                        render: function (data, type, row) {
                            // if (data.position === "") {
                            return `<button
                                        type="button"
                                        class="btn btn-icon btn-primary view-dealer-info"
                                        data-citizenid="${data.personal_id}"
                                        data-dealerid="${data.userid}"
                                        >
                                            <i class="fa fa-search"></i>
                                    </button>`;
                            // } else {
                            //     return "";
                            // }
                        },
                    },
                    {
                        title: "ชื่อสมาชิก",
                        // data: "name",
                        data: function (value) {
                            if (value.name !== null && value.name !== "") {
                                return value.name;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "หมายเลขบัตรประชาชน",
                        data: function (value) {
                            if (
                                value.personal_id !== null &&
                                value.personal_id !== ""
                            ) {
                                return value.personal_id;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "เบอร์ติดต่อกลับ",
                        data: function (value) {
                            if (
                                value.contact_number !== null &&
                                value.contact_number !== ""
                            ) {
                                return value.contact_number;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "วันที่สมัคร",
                        data: "register_date",
                    },
                    {
                        title: "สถานะการชำระเงิน",
                        className: "dt-body-center",
                        data: function (value) {
                            if (value.status === "active") {
                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">ชำระเรียบร้อยแล้ว</span>';
                            } else {
                                return '<span class="label label-secondary label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                            }
                        },
                    },
                ],
            });
        });
    }, []);

    useEffect(() => {
        $(document).ready(function () {
            $(".view-dealer-info").click(function () {
                prop.setDealerid($(this).attr("data-dealerid"));
                prop.getDealerInfo($(this).attr("data-citizenid"));
            });
        });
    });

    return (
        <table
            id="kt_datatable"
            className="table table-striped table-bordered"
        ></table>
    );
}
