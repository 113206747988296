import React, { useEffect } from "react";

import $ from "jquery";

export function CommissionTable(prop) {
    useEffect(() => {
        $(document).ready(function () {
            $("#kt_datatable").DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: 10,
                responsive: true,
                dom: "Bfrtip",
                buttons: ["excel"],
                // order: [[4, "asc"]],
                language: {
                    paginate: {
                        previous: "ก่อนหน้า",
                        next: "ถัดไป",
                    },
                    info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                    emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                    infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                    zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                },
                oLanguage: {
                    sSearch: "คำสำคัญ",
                },
                columns: [
                    {
                        title: "หมายเลขประจำตัวดีลเลอร์",
                        // data: "name",
                        data: function (value) {
                            if (value.id_card !== null && value.id_card !== "") {
                                return value.id_card;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "เบอร์ของดีลเลอร์",
                        data: function (value) {
                            if (
                                value.phonenumber !== null &&
                                value.phonenumber !== ""
                            ) {
                                return value.phonenumber;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "ชื่อ - นามสกุล",
                        data: function (value) {
                            if (
                                value.name !== null &&
                                value.name !== ""
                            ) {
                                return value.name;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "ค่าแนะนำ",
                        data: function (value) {
                            if (
                                value.fast_start !== null &&
                                value.fast_start !== ""
                            ) {
                                return value.fast_start;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "การเติมเงิน",
                        // className: "dt-body-center",
                        data: function (value) {
                            if (
                                value.topup !== null &&
                                value.topup !== ""
                            ) {
                                return value.topup;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "One Time Reward",
                        // className: "dt-body-center",
                        data: function (value) {
                            if (
                                value.one_time_reward !== null &&
                                value.one_time_reward !== ""
                            ) {
                                return value.one_time_reward;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "Pool Bonus",
                        // className: "dt-body-center",
                        data: function (value) {
                            if (
                                value.pool !== null &&
                                value.pool !== ""
                            ) {
                                return value.pool;
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        title: "Promotion",
                        // className: "dt-body-center",
                        data: function (value) {
                            if (
                                value.promotion !== null &&
                                value.promotion !== ""
                            ) {
                                return value.promotion;
                            } else {
                                return "-";
                            }
                        },
                    },
                ],
            });
        });
    }, []);

    return (
        <table
            id="kt_datatable"
            className="table table-striped table-bordered"
        ></table>
    );
}