import React, { useState } from 'react';
// import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
    //FormattedMessage,
    injectIntl
} from 'react-intl';
import * as auth from '../_redux/authRedux';
// import { login } from "../_redux/authCrud";

import config from '../../../config';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    username: '',
    password: ''
};

function Login(props) {
    // const { intl } = props;
    const [ loading, setLoading ] = useState(false);
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            // .min(3, "Minimum 3 symbols")
            // .max(50, "Maximum 50 symbols")
            .required(
                // intl.formatMessage({
                //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
                // })
                'กรุณากรอกชื่อผู้ใช้งาน'
            ),
        password: Yup.string()
            // .min(3, "Minimum 3 symbols")
            // .max(50, "Maximum 50 symbols")
            .required(
                // intl.formatMessage({
                //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
                // })
                'กรุณากรอกรหัสผ่าน'
            )
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }

        // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        //     return "is-valid";
        // }

        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            fetch(config.url + 'admin/auth/login', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    secret: config.secret
                },
                body: JSON.stringify({
                    username: values.username,
                    password: values.password
                })
            })
                .then((response) => response.json())
                .then((result) => {
                    disableLoading();
                    if (result.resposecode === '0') {
                        props.fulfillUser(result);
                    } else {
                        setStatus('ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง');
                        setSubmitting(false);
                    }
                    // props.login(accessToken);
                })
                .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus('ไม่สามารถตรวจสอบข้อมูลผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง');
                    // setStatus(
                    //     intl.formatMessage({
                    //         id: "AUTH.VALIDATION.INVALID_LOGIN",
                    //     })
                    // );
                });
        }
    });

    return (
        <div className='login-form login-signin' id='kt_login_signin_form'>
            {/* begin::Head */}
            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1 text-brown'>
                    {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
                    ระบบหลังบ้านโครงการซิมปันสุข
                </h3>
                <p className='text-muted font-weight-bold'>กรุณากรอกชื่อผู้ใช้และรหัสผ่านของคุณ</p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
                {formik.status ? (
                    <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible rounded-lg'>
                        <div className='alert-text'>
                            <strong>
                                <u>ผิดพลาด</u>
                            </strong>
                            {' ' + formik.status}
                        </div>
                    </div>
                ) : null
                // <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                //     <div className="alert-text ">
                //         Use account <strong>admin@demo.com</strong> and
                //         password <strong>demo</strong> to continue.
                //     </div>
                // </div>
                }

                <div className='form-group fv-plugins-icon-container'>
                    <input
                        placeholder='ชื่อผู้ใช้งาน'
                        type='text'
                        className={`form-control form-control-solid h-auto py-5 px-6 rounded-lg ${getInputClasses(
                            'username'
                        )}`}
                        name='username'
                        {...formik.getFieldProps('username')}
                    />
                    {formik.touched.username && formik.errors.username ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.username}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group fv-plugins-icon-container'>
                    <input
                        placeholder='รหัสผ่าน'
                        type='password'
                        className={`form-control form-control-solid h-auto py-5 px-6 rounded-lg ${getInputClasses(
                            'password'
                        )}`}
                        name='password'
                        {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
                    {/* <Link
                        to="/auth/forgot-password"
                        className="text-dark-50 text-hover-primary my-3 mr-2"
                        id="kt_login_forgot"
                    >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link> */}
                    <button
                        id='kt_login_signin_submit'
                        type='submit'
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary rounded-lg font-weight-bold px-9 py-4 my-3 ml-auto`}
                    >
                        <span>เข้าสู่ระบบ</span>
                        {loading && <span className='ml-3 spinner spinner-white' />}
                    </button>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
