/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    const history = useHistory();
    const logoutClick = () => {
        const toggle = document.getElementById("kt_quick_user_toggle");
        if (toggle) {
            toggle.click();
        }
        history.push("/logout");
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/dashboard",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="ภาพรวม"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Home/Home.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">ภาพรวม</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">ศูนย์บริการข้อมูลลูกค้า</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/order/report",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/order/report">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="ข้อมูลรายการสั่งซื้อ"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Files/File.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">ข้อมูลรายการสั่งซื้อ</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/shipping/report",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/shipping/report">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="ข้อมูลการจัดส่ง"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Shopping/Cart1.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">ข้อมูลการจัดส่ง</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/dealer/report",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/dealer/report">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="ข้อมูลสมาชิก"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/General/User.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">ข้อมูลสมาชิก</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/stock/manage",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/stock/manage">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="สต๊อกเบอร์"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Shopping/Box2.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">สต๊อกเบอร์</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/commission/report",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/commission/report">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="สต๊อกเบอร์"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Shopping/Dollar.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">ค่าคอมมิชชั่น</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/position/report",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/position/report">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="สต๊อกเบอร์"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Group.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">ตำแหน่งดีลเลอร์</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/* begin::section */}
                <li className="menu-section ">
                    <h4 className="menu-text">การบัญชี</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                {/* end:: section */}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/salevat/report",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/salevat/report">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="ข้อมูลรายการการสั่งซื้อแจงภาษี"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Files/Selected-file.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">
                            ข้อมูลรายการการสั่งซื้อแจงภาษี
                        </span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/withdraw/report",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/withdraw/report">
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="ข้อมูลรายการการถอนเงิน"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Outgoing-box.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">
                            ข้อมูลรายการการถอนเงิน
                        </span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}

                <br />

                {/*begin::1 Level*/}
                <li className="menu-item" aria-haspopup="true">
                    <div className="menu-link" onClick={logoutClick}>
                        <span className="svg-icon menu-icon">
                            <SVG
                                title="ออกจากระบบ"
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/Navigation/Sign-out.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">ออกจากระบบ</span>
                    </div>
                </li>
                {/*end::1 Level*/}
            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
