import React, {
    Suspense,
    // lazy
} from "react";
import {
    Redirect,
    Switch,
    // Route
} from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
// import { DashboardPage } from "./pages/DashboardPage";

import { Dashboard } from "./pages/custom/Dashboard";
import { OrderReport } from "./pages/custom/order/Report";
import { ShippingReport } from "./pages/custom/shipping/Report";
import { DealerReport } from "./pages/custom/dealer/Report";
import { StockManage } from "./pages/custom/stock/Manage";
import { SalevatReport } from "./pages/custom/salevat/Report";
import { WithdrawReport } from "./pages/custom/withdraw/Report";
import { CommissionReport } from "./pages/custom/commission/Report";
import { PositionReport } from "./pages/custom/position/Report";

// const GoogleMaterialPage = lazy(() =>
//     import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//     import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//     import("./modules/ECommerce/pages/eCommercePage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={Dashboard} />
                {/* <ContentRoute path="/dashboard2" component={DashboardPage} /> */}
                <ContentRoute path="/order/report" component={OrderReport} />
                <ContentRoute
                    path="/shipping/report"
                    component={ShippingReport}
                />
                <ContentRoute path="/dealer/report" component={DealerReport} />
                <ContentRoute path="/stock/manage" component={StockManage} />
                <ContentRoute
                    path="/salevat/report"
                    component={SalevatReport}
                />
                <ContentRoute
                    path="/withdraw/report"
                    component={WithdrawReport}
                />
                <ContentRoute
                    path="/commission/report"
                    component={CommissionReport}
                />
                <ContentRoute
                    path="/position/report"
                    component={PositionReport}
                />
                {/* <ContentRoute path="/my-page" component={MyPage} /> */}
                {/* <Route path="/google-material" component={GoogleMaterialPage} />
                <Route path="/react-bootstrap" component={ReactBootstrapPage} />
                <Route path="/e-commerce" component={ECommercePage} /> */}
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
