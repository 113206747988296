import React, { useState } from "react";

import Select, { components } from "react-select";

import Swal from "sweetalert2/dist/sweetalert2.js";

import { ManageTable } from "./Table";

import config from "../../../config";

const controlStyles = {
    borderRadius: "0.85rem",
    // padding: "5px",
    background: "#fff",
    // color: "white",
};

const ControlComponent = (props) => (
    <div className="form-control rounded-lg pt-0" style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#fff",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 1051,
        // position: "relative",
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
    }),
};

const statuslist = [
    {
        value: "0",
        label: "เบอร์ที่พร้อมขายหรือสมัคร",
    },
    {
        value: "1",
        label: "เบอร์ที่ถูกจองอยู่",
    },
    {
        value: "4",
        label: "เบอร์ที่ถูกปิดการใช้งาน",
    },
];

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton:
            "btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
        cancelButton:
            "btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
    },
    buttonsStyling: false,
});

export function StockManage() {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [phonenumber, setPhonenumber] = useState("");
    const [status, setStatus] = useState("");
    const [response, setResponse] = useState({});

    const selectStatus = (item) => {
        if (item) {
            setStatus(item);
        } else {
            setStatus("");
        }
    };

    const handleSearch = () => {
        let postparam = {
            filters: {},
        };
        if (phonenumber === "" && status === "") {
            swalWithBootstrapButtons.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณากรอกหมายเลขหรือสถานะที่ต้องการจัดการ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (phonenumber !== "") {
            postparam["filters"]["phonenumber"] = phonenumber;
        }
        if (status !== "") {
            postparam["filters"]["status"] = status.value;
        }
        getSimList(postparam);
    };

    const getSimList = (postparam) => {
        setLoading(true);
        setSearch(true);
        setResponse({});
        fetch(config.url + "admin/sim/lists", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const unlockNumber = (phonenumber, status) => {
        swalWithBootstrapButtons
            .fire({
                // title: 'ยกเลิกคำสั่ง',
                html:
                    `<div>ต้องการ${status === "n/a" ? "ปลดล็อค" : "ล็อค"}การจองของหมายเลข</div>` +
                    "<div class='text-danger'>" +
                    phonenumber.slice(0, 3) +
                    "-" +
                    phonenumber.slice(3, 6) +
                    "-" +
                    phonenumber.slice(6) +
                    "</div>" +
                    "<div>ใช่หรือไม่ ?</div>",
                icon: "question",
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonText: "ยืนยัน",
                cancelButtonText: "ยกเลิก",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let statusnumber = status === "available" ? "4" : "0";
                    setLoading(true);
                    fetch(config.url + "admin/sim/set-simstatus", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            secret: config.secret,
                        },
                        body: JSON.stringify({
                            phonenumber: phonenumber,
                            status: statusnumber
                        }),
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            setLoading(false);
                            console.log(result);
                            if (result.responsecode === 0) {
                                swalWithBootstrapButtons.fire({
                                    // title: 'ยกเลิกคำสั่ง',
                                    html: "ปลดล็อคการจองเรียบร้อย",
                                    icon: "success",
                                    confirmButtonText: "ยืนยัน",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                });
                            } else {
                                swalWithBootstrapButtons.fire({
                                    // title: 'ยกเลิกคำสั่ง',
                                    html:
                                        "ปลดล็อคการจองไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
                                    icon: "warning",
                                    confirmButtonText: "ยืนยัน",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                });
                            }
                        })
                        .catch((err) => {
                            setLoading(false);
                            console.log(err);
                        });
                }
            });
    };
    return (
        <>
            {loading ? (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-custom card-stretch gutter-b rounded-lg">
                            <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fa fa-search text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        ค้นหา
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="phonenumber">
                                            หมายเลขที่ต้องการจัดการ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="phonenumber"
                                            value={phonenumber}
                                            onChange={(e) =>
                                                setPhonenumber(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="phonenumber">
                                            สถานะของหมายเลข
                                        </label>
                                        <Select
                                            components={{
                                                Control: ControlComponent,
                                            }}
                                            noOptionsMessage={() =>
                                                "ไม่พบข้อมูล"
                                            }
                                            value={status}
                                            options={statuslist}
                                            placeholder="เลือก"
                                            onChange={selectStatus}
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button
                                    type="button"
                                    onClick={handleSearch}
                                    className="btn btn-sm btn-primary rounded-lg"
                                >
                                    <i className="far fa-dot-circle"></i> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                    {search ? (
                        <div className="col-lg-12">
                            <div className="card card-custom card-stretch gutter-b rounded-lg">
                                <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                    <div className="card-title">
                                        <h3 className="card-label text-white">
                                            รายงาน
                                        </h3>
                                    </div>
                                    <div className="card-toolbar">
                                        จำนวนผลลัพท์{" "}
                                        {Object.keys(response).length > 0
                                            ? response.responsecode === "0"
                                                ? Object.keys(response.lists)
                                                      .length
                                                : "0"
                                            : "0"}{" "}
                                        รายการ
                                    </div>
                                </div>
                                <div className="card-body">
                                    {Object.keys(response).length > 0 ? (
                                        <>
                                            {response.responsecode === "0" ? (
                                                <ManageTable
                                                    data={response.lists}
                                                    unlockNumber={unlockNumber}
                                                    status={status}
                                                />
                                            ) : (
                                                // <DealerTable
                                                //     data={response.lists}
                                                //     getDealerInfo={getDealerInfo}
                                                //     setDealerid={setDealerid}
                                                // />
                                                <div className="col-xl-12 text-center m-0 my-25 p-0">
                                                    <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                                                    <h4 className="mt-3">
                                                        ไม่พบข้อมูลที่ต้องการจัดการ
                                                    </h4>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="col-xl-12 text-center m-0 my-25 p-0">
                                            <i className="fas fa-exclamation-circle fa-5x mb-2 text-danger"></i>
                                            <h4 className="mt-3">
                                                ไม่สามารถดึงข้อมูลได้
                                                กรุณาลองใหม่อีกครั้ง
                                            </h4>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </>
    );
}
