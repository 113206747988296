import React, { useCallback, useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import Moment from "moment";

import DatePicker, { utils } from "react-modern-calendar-datepicker";

import { CSVLink } from "react-csv";

import { useDropzone } from 'react-dropzone';

import Swal from "sweetalert2/dist/sweetalert2.js";

import { ShippingTable } from "./Table";

import config from "../../../config";


const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton:
            "btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
        cancelButton:
            "btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto",
    },
    buttonsStyling: false,
});

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const myCustomLocale = {
    // months list by order
    months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ],
    // week days by order
    weekDays: [
        {
            name: "อาทิตย์", // used for accessibility
            short: "อา", // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: "จันทร์",
            short: "จ",
        },
        {
            name: "อังคาร",
            short: "อ",
        },
        {
            name: "พุธ",
            short: "พ",
        },
        {
            name: "พฤหัสบดี",
            short: "พฤ",
        },
        {
            name: "ศุกร์",
            short: "ศ",
        },
        {
            name: "เสาร์",
            short: "ส",
            isWeekend: true,
        },
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: "Next Month", // texts in the date picker
    previousMonth: "Previous Month", // texts in the date picker
    openMonthSelector: "Open Month Selector", // texts in the date picker
    openYearSelector: "Open Year Selector", // texts in the date picker
    closeMonthSelector: "Close Month Selector", // texts in the date picker
    closeYearSelector: "Close Year Selector", // texts in the date picker
    defaultPlaceholder: "Select...", // texts in the date picker
    from: "from", // for input range value
    to: "to", // for input range value
    digitSeparator: ",", // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false, // is your language rtl or ltr?
};

const minimumDate = {
    year: 2020,
    month: 12,
    day: 31,
};

export function ShippingReport() {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({});
    const [dataset, setDataset] = useState([]);

    const [name, setName] = useState("");
    const [date, setDate] = useState({
        from: null,
        to: null,
    });
    const [ordernumber, setOrdernumber] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [importfile, setImport] = useState("");

    const [showModal, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to
                    ? date.from.year +
                      "/" +
                      ("0" + date.from.month).slice(-2) +
                      "/" +
                      ("0" + date.from.day).slice(-2) +
                      " - " +
                      date.to.year +
                      "/" +
                      ("0" + date.to.month).slice(-2) +
                      "/" +
                      ("0" + date.to.day).slice(-2)
                    : ""
            }
            className="form-control rounded-lg"
            type="text"
            id="dateperiod"
            name="dateperiod"
        />
    );
    useEffect(() => {
        let postparam = {
            filter: { start: Moment().format("YYYY-MM-DD") },
        };
        getShippingOrder(postparam);
    }, []);

    const handleDownloadKerryFormat = () => {
        let param = {};
        let postparam = {};
        if (name !== "") {
            param["name"] = name;
        }
        if (ordernumber !== "") {
            param["bill_no"] = ordernumber;
        }
        if (phonenumber !== "") {
            param["number"] = phonenumber;
        }
        if (date.from !== null && date.to !== null) {
            param["start"] =
                date.from.year +
                "-" +
                ("0" + date.from.month).slice(-2) +
                "-" +
                ("0" + date.from.day).slice(-2);
            param["end"] =
                date.to.year +
                "-" +
                ("0" + date.to.month).slice(-2) +
                "-" +
                ("0" + date.to.day).slice(-2);
        }
        if (Object.keys(param).length > 0) {
            postparam["filter"] = param;
        } else {
            let today = new Date();
            postparam["filter"] = {
                "start": Moment(today).format('YYYY-MM-DD'),
                "end": Moment(today).format('YYYY-MM-DD')
            }
        }
        downloadKerryFormat(postparam);
    }

    const downloadKerryFormat = (postparam) => {
        setLoading(true);
        fetch(config.url + "admin/order/export-shipping", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                console.log(result);
                if (result.responsecode === 0) {
                    var link = document.createElement("a");
                    document.body.appendChild(link);
                    link.setAttribute("type", "hidden");
                    link.href = "data:text/plain;base64," + result.base64;
                    link.download = `KerryExpressImportTemplate${Moment(new Date(postparam.filter.start)).format("DDMMYYYY")}.xlsx`;
                    link.click();
                    document.body.removeChild(link);
                } else {
                    swalWithBootstrapButtons.fire({
                        html: "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง",
                        icon: "warning",
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                    return;
                }
            })
            .catch((err) => {
                setLoading(false);
                swalWithBootstrapButtons.fire({
                    html: "มีบางอย่างผิดพลาด กรุณาติดต่อเจ้าหน้าที่",
                    icon: "error",
                    confirmButtonText: "รับทราบ",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
                return;
            });
    }

    const getShippingOrder = (postparam) => {
        setResponse({});
        setDataset({});
        fetch(config.url + "admin/order/shipping", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
                if (Object.keys(result).length > 0) {
                    if (result.responsecode === "00") {
                        let array = [];
                        for (let prop in result.lists) {
                            let objectdata = {
                                order_number:
                                    result.lists[prop]["order_number"],
                                // number:
                                //     result.lists[prop]["number"].slice(0, 3) +
                                //     "-" +
                                //     result.lists[prop]["number"].slice(3, 6) +
                                //     "-" +
                                //     result.lists[prop]["number"].slice(6),
                                pcode: result.lists[prop]["product_code"],
                                number: result.lists[prop]["number"],
                                idcard: result.lists[prop]["idcard"],
                                name: result.lists[prop]["name"],
                                amount: result.lists[prop]["amount"],
                                paid_date: result.lists[prop]["paid_date"],
                                shipping: {
                                    // contact_number:
                                    //     result.lists[prop]["shipping"][
                                    //         "contact_number"
                                    //     ].slice(0, 3) +
                                    //     "-" +
                                    //     result.lists[prop]["shipping"][
                                    //         "contact_number"
                                    //     ].slice(3, 6) +
                                    //     "-" +
                                    //     result.lists[prop]["shipping"][
                                    //         "contact_number"
                                    //     ].slice(6),
                                    contact_number:
                                        result.lists[prop]["shipping"][
                                            "contact_number"
                                        ],
                                    address:
                                        result.lists[prop]["shipping"][
                                            "address"
                                        ],
                                    district:
                                        result.lists[prop]["shipping"][
                                            "district"
                                        ],
                                    amphur:
                                        result.lists[prop]["shipping"][
                                            "amphur"
                                        ],
                                    province:
                                        result.lists[prop]["shipping"][
                                            "province"
                                        ],
                                    zipcode:
                                        result.lists[prop]["shipping"][
                                            "zipcode"
                                        ],
                                },
                                trackingnumber: result.lists[prop]["tracking_number"],
                                shippeddate: result.lists[prop]["shipped_date"],
                            };
                            array.push(objectdata);
                        }
                        setDataset(array);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const handleSearch = () => {
        let param = {};
        let postparam = {};
        if (name !== "") {
            param["name"] = name;
        }
        if (ordernumber !== "") {
            param["bill_no"] = ordernumber;
        }
        if (phonenumber !== "") {
            param["number"] = phonenumber;
        }
        if (date.from !== null && date.to !== null) {
            param["start"] =
                date.from.year +
                "-" +
                ("0" + date.from.month).slice(-2) +
                "-" +
                ("0" + date.from.day).slice(-2);
            param["end"] =
                date.to.year +
                "-" +
                ("0" + date.to.month).slice(-2) +
                "-" +
                ("0" + date.to.day).slice(-2);
        }
        if (param) {
            postparam["filter"] = param;
        }
        setLoading(true);
        getShippingOrder(postparam);
    };

    const Excelheaders = [
        { label: "เลขคำสั่งซื้อ", key: "order_number" },
        { label: "รหัสสินค้า", key: "pcode"},
        { label: "เบอร์สั่งซื้อ", key: "number" },
        { label: "เลขบัตรประชาชน", key: "idcard" },
        { label: "ชื่อผู้รับ", key: "name" },
        { label: "มูลค่า", key: "amount" },
        { label: "วันที่สั่งซื้อ", key: "paid_date" },
        { label: "เบอร์ติดต่อกลับ", key: "shipping.contact_number" },
        { label: "ที่อยู่", key: "shipping.address" },
        { label: "ตำบล", key: "shipping.district" },
        { label: "อำเภอ", key: "shipping.amphur" },
        { label: "จังหวัด", key: "shipping.province" },
        { label: "รหัสไปรษณย์", key: "shipping.zipcode" },
        { label: "หมายเลขพัสดุ", key: "trackingnumber" },
        { label: "วันที่จัดส่ง", key: "shippeddate" },
    ];

    const importFile = () => {
        if (importfile === "") {
            swalWithBootstrapButtons.fire({
                html: "กรุณาเลือกไฟล์ที่ต้องการอัพโหลด",
                icon: "warning",
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        setLoading(true);
        fetch(config.url + "admin/order/import-shipping", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                base64: importfile
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    swalWithBootstrapButtons.fire({
                        html: "ดำเนินการเรียบร้อย",
                        icon: "success",
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                        }
                    });
                } else {
                    swalWithBootstrapButtons.fire({
                        html: "ไม่สามารถดำเนินการได้ กรุณาลองใหม่อีกครั้ง",
                        icon: "warning",
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                swalWithBootstrapButtons.fire({
                    html: "ไม่สามารถดำเนินการได้ กรุณาติดต่อเจ้าหน้าที่",
                    icon: "error",
                    confirmButtonText: "รับทราบ",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                });
            });
    }

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const binaryStr = reader.result.split(',').pop()
                setImport(binaryStr);
            }
            reader.readAsDataURL(file)
        })
    }, [])

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const files = acceptedFiles.map((file, index) => {
        return (
            <div className="dz-preview dz-file-preview dz-processing dz-error dz-complete" key={index}>
                <div className="dz-image">
                    {/* {file.path.includes('.xlsx') ? (
                        <i className='far fa-file-excel'/>
                    ) : (
                        <i className='far fa-file-alt' />
                    )} */}
                    {/* <img data-dz-thumbnail alt="thumbnail"/> */}
                </div>
                <div className="dz-details">
                    <div className="dz-size">
                        <span data-dz-size>
                            <strong>{formatBytes(file.size).split(' ')[0]}</strong> {formatBytes(file.size).split(' ')[1]}
                        </span>
                    </div>
                    <div className="dz-filename">
                        <span data-dz-name>{file.path}</span>
                    </div>
                </div>
                {/* <div className="dz-progress">
                    <span className="dz-upload" data-dz-uploadprogress />
                </div>
                <div className="dz-error-message">
                    <span data-dz-errormessage>Server responded with 0 code.</span>
                </div>
                <div className="dz-success-mark">
                    <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>Check</title>
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" strokeOpacity="0.198794158" stroke="#747474" fillOpacity="0.816519475" fill="#FFFFFF" />
                        </g>
                    </svg>
                </div>
                <div className="dz-error-mark">
                    <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>Error</title>
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g stroke="#747474" strokeOpacity="0.198794158" fill="#FFFFFF" fillOpacity="0.816519475">
                                <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" />
                            </g>
                        </g>
                    </svg>
                </div>
                <a className="dz-remove" href="javascript:undefined;" data-dz-remove>
                    Remove file
                </a> */}
            </div>
        )
    });

    return (
        <>
            {loading ? (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-custom card-stretch gutter-b rounded-lg">
                            <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fa fa-search text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        ค้นหา
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="ordername">
                                            ชื่อผู้สั่งซื้อ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="ordername"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="orderdate">
                                            วันที่สั่งซื้อ
                                        </label>
                                        <DatePicker
                                            value={date}
                                            onChange={setDate}
                                            renderInput={renderCustomInput}
                                            minimumDate={minimumDate}
                                            maximumDate={utils().getToday()}
                                            locale={myCustomLocale}
                                            wrapperClassName="col-12 p-0"
                                            calendarClassName="responsive-calendar"
                                            calendarPopperPosition="bottom"
                                            shouldHighlightWeekends
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="ordernumber">
                                            หมายเลขคำสั่งซื้อ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="ordernumber"
                                            value={ordernumber}
                                            onChange={(e) =>
                                                setOrdernumber(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* <div className="form-group col-sm-4">
                                        <label htmlFor="citizenid">
                                            หมายเลขบัตรประชาชนของผู้สั่งซื้อ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="citizenid"
                                            // value={citizenid}
                                            // onChange={(e) =>
                                            //     setCitizenid(e.target.value)
                                            // }
                                        />
                                    </div> */}
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="phonenumber">
                                            เบอร์โทรศัพท์ที่สั่งซื้อ
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control rounded-lg"
                                            name="phonenumber"
                                            value={phonenumber}
                                            onChange={(e) =>
                                                setPhonenumber(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button
                                    type="button"
                                    onClick={handleSearch}
                                    className="btn btn-sm btn-primary rounded-lg"
                                >
                                    <i className="far fa-dot-circle"></i> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="card card-custom card-stretch gutter-b rounded-lg">
                            <div className="card-header bg-primary text-white rounded-lg rounded-bottom-0">
                                <div className="card-title">
                                    <h3 className="card-label text-white">
                                        รายงาน
                                    </h3>
                                </div>
                                <div className="card-toolbar">
                                    {/* จำนวนผลลัพท์{" "}
                                    {Object.keys(response).length > 0
                                        ? response.responsecode === "00"
                                            ? Object.keys(response.lists).length
                                            : null
                                        : null}{" "}
                                    รายการ */}
                                    {Object.keys(response).length > 0 ? (
                                        response.responsecode === "00" ? (
                                            dataset.length > 0 ? (
                                                <CSVLink
                                                    data={dataset}
                                                    headers={Excelheaders}
                                                    className="btn btn-secondary rounded-lg d-none d-md-block"
                                                    target="_blank"
                                                >
                                                    ดาวน์โหลด Excel
                                                </CSVLink>
                                            ) : null
                                        ) : null
                                    ) : null}
                                    {/* <DropdownButton
                                        alignRight
                                        title="คำสั่งต่างๆ"
                                        variant="secondary"
                                        onSelect={handleDropdown}
                                        className="rounded-lg"
                                    >
                                        <Dropdown.Item eventKey="download">
                                            <CSVLink
                                                data={Exceldata}
                                                headers={Excelheaders}
                                            >
                                                Download me
                                            </CSVLink>
                                            ดาวน์โหลด Excel
                                        </Dropdown.Item>
                                    </DropdownButton> */}
                                    <button type='button' className='btn btn-secondary rounded-lg d-none d-md-block ml-5' onClick={handleDownloadKerryFormat}>
                                        ดาวน์โหลด Kerry Format
                                    </button>
                                    <button type='button' className='btn btn-secondary rounded-lg d-none d-md-block ml-5' onClick={handleShow}>
                                        นำเข้า Excel
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                {Object.keys(response).length > 0 ? (
                                    <>
                                        {response.responsecode === "00" ? (
                                            <ShippingTable
                                                data={response.lists}
                                                // getOrderInfo={getOrderInfo}
                                            />
                                        ) : (
                                            <div className="col-xl-12 text-center m-0 my-25 p-0">
                                                <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                                                <h4 className="mt-3">
                                                    ไม่พบข้อมูลที่ต้องการค้นหา
                                                </h4>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="col-xl-12 text-center m-0 my-25 p-0">
                                        <i className="fas fa-exclamation-circle fa-5x mb-2 text-danger"></i>
                                        <h4 className="mt-3">
                                            ไม่สามารถดึงข้อมูลได้
                                            กรุณาลองใหม่อีกครั้ง
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                show={showModal}
                onHide={handleClose}
                size="xl"
                centered
                aria-labelledby="unactive-number-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="unactive-number-modal"
                        className="col-11 p-0"
                    >
                        เพิ่มข้อมูลหมายเลขพัสดุ
                    </Modal.Title>
                    <button
                        type="button"
                        className="close col-1 text-right pr-3"
                        onClick={handleClose}
                    >
                        <i className="ki ki-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center">
                        {/* <div className="col-sm-12 d-flex justify-content-between py-5">
                            <span className='my-auto mr-15 font-weight-bolder font-size-lg'>ดาวน์โหลดตัวอย่างไฟล์ : </span>
                            <span className='text-right'>
                                <button type="button" className="btn btn-primary rounded-lg" onClick={() => downloadExcelFile(prop.response.base64)}>
                                    <i className="fas fa-file-excel"></i> ดาวน์โหลด Excel
                                </button>
                            </span>
                        </div> */}
                        <div className="col-sm-12">
                            <div {...getRootProps({className: 'dropzone dropzone-default dz-clickable cus-dropzone bg-white'})}>
                                <input {...getInputProps()} />
                                {acceptedFiles.length === 0 ? (
                                    <>
                                        <div className="uppy-DragDrop-inner">
                                            <svg aria-hidden="true" focusable="false" className="uppy-c-icon uppy-DragDrop-arrow" width={16} height={16} viewBox="0 0 16 16">
                                                <path d="M11 10V0H5v10H2l6 6 6-6h-3zm0 0" fillRule="evenodd" />
                                            </svg>
                                            <div className="uppy-DragDrop-label">
                                                วางไฟล์ที่นี่หรือ<span className="uppy-DragDrop-browse">เรียกดู</span>
                                            </div>
                                            <span className="uppy-DragDrop-note" />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {files}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12 d-flex justify-content-end pt-5">
                            <button type="button" className="btn btn-success rounded-lg" onClick={importFile}>
                                <i className="fas fa-file-upload"></i> อัพโหลด
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
